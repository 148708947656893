import { useState } from "react";

import { PrimaryButton } from "../../../components/common";
import { IoReload } from "react-icons/io5";
import { constants } from "../../../helpers";
import {
  DeviceAppsTypes,
  DeviceConnectionStatusTypes,
  DeviceConnectTypes,
  DeviceFWTypes,
  DeviceTypes,
} from "../../../helpers/enums";

const DevicesFilters = ({
  closeModal,
  apiConnectTypes,
  fromDiscoveredDevices,
  setFiltersData,
  filtersData,
}) => {
  const connectTypes = apiConnectTypes;
  const conStatus = [
    DeviceConnectionStatusTypes.ONLINE.name,
    DeviceConnectionStatusTypes.OFFLINE.name,
  ];
  const divStatus = [DeviceTypes.DEACTIVATED.name, DeviceTypes.CLAIMING.name];
  const firmwareStatus = [
    DeviceFWTypes.AVAILABLE.name,
    DeviceFWTypes.NOTAVAILABLE.name,
  ];
  const apps = [
    DeviceAppsTypes.ONCLOUD.name,
    DeviceAppsTypes.SIGHTMIND.name,
    DeviceAppsTypes.FLEXAI.name,
    DeviceAppsTypes.DMPRO.name,
  ];

  const [connectType, setConnectType] = useState(filtersData?.connectType);
  const [connectionStatus, setConnectionStatus] = useState(
    filtersData?.connectionStatus
  );
  const [deviceStatus, setDeviceStatus] = useState(filtersData?.deviceStatus);
  const [firmwareUpdate, setFirmwareUpdate] = useState(
    filtersData?.firmwareUpdate
  );
  const [applicationsLinked, setApplicationsLinked] = useState(
    filtersData?.applicationsLinked
  );

  const onClickOk = () => {
    const filtersData = {};
    filtersData.connectType = connectType;
    filtersData.connectionStatus = connectionStatus;
    filtersData.deviceStatus = deviceStatus;
    filtersData.firmwareUpdate = firmwareUpdate;
    filtersData.applicationsLinked = applicationsLinked;
    setFiltersData(filtersData);
    closeModal();
  };

  const onClickClear = () => {
    setConnectType([]);
    setConnectionStatus([]);
    setDeviceStatus([]);
    setFirmwareUpdate([]);
    setApplicationsLinked([]);
  };

  const onChanegCheckBox = (e, id, name) => {
    const { checked } = e.target;
    let checkList = structuredClone(
      id === DeviceConnectTypes.id
        ? connectType
        : id === DeviceConnectionStatusTypes.id
        ? connectionStatus
        : id === DeviceTypes.id
        ? deviceStatus
        : id === DeviceFWTypes.id
        ? firmwareUpdate
        : id === DeviceAppsTypes.id
        ? applicationsLinked
        : []
    );
    if (checked && name) {
      checkList.push(name);
    } else if (name) {
      const index = checkList.indexOf(name);
      if (index > -1) {
        checkList.splice(index, 1);
      }
    }

    if (id === DeviceConnectTypes.id && name) {
      setConnectType([...new Set(checkList)]);
    } else if (id === DeviceConnectTypes.id && !name) {
      setConnectType(checked ? connectTypes : []);
    } else if (id === DeviceConnectionStatusTypes.id && name) {
      setConnectionStatus([...new Set(checkList)]);
    } else if (id === DeviceConnectionStatusTypes.id && !name) {
      setConnectionStatus(checked ? conStatus : []);
      setDeviceStatus(checked ? divStatus : []);
    } else if (id === DeviceTypes.id && name) {
      setDeviceStatus([...new Set(checkList)]);
    } else if (id === DeviceTypes.id && !name) {
      setConnectionStatus(checked ? conStatus : []);
      setDeviceStatus(checked ? divStatus : []);
    } else if (id === DeviceFWTypes.id && name) {
      setFirmwareUpdate([...new Set(checkList)]);
    } else if (id === DeviceFWTypes.id && !name) {
      setFirmwareUpdate(checked ? firmwareStatus : []);
    } else if (id === DeviceAppsTypes.id && name) {
      setApplicationsLinked([...new Set(checkList)]);
    } else if (id === DeviceAppsTypes.id && !name) {
      setApplicationsLinked(checked ? apps : []);
    }
  };

  const getRowData = (details, id) => {
    const checkedStatus =
      id === DeviceConnectTypes.id
        ? connectType.includes(details?.name)
        : id === DeviceConnectionStatusTypes.id
        ? connectionStatus.includes(details?.name)
        : id === DeviceTypes.id
        ? deviceStatus.includes(details?.name)
        : id === DeviceFWTypes.id
        ? firmwareUpdate.includes(details?.name)
        : id === DeviceAppsTypes.id
        ? applicationsLinked.includes(details?.name)
        : false;
    const requestObject = {
      target: {
        checked: !checkedStatus,
      },
    };
    return (
      <div
        className={`filter-row-container ${
          !details?.displayName ? "hide" : ""
        }`}
        role="button"
        onClick={(e) => onChanegCheckBox(requestObject, id, details?.name)}
      >
        <div className="filter-checkbox">
          <input
            type="checkbox"
            checked={checkedStatus}
            onChange={(e) => onChanegCheckBox(e, id, details?.name)}
          />
        </div>
        {details?.displayName}
      </div>
    );
  };

  return (
    <>
      <div className="device-filter-modal-wrapper">
        <div className="filter-table-structure">
          <div className="filter-column filter-column-border">
            <div className="filter-header">
              {constants.DEVICES_FILTER_CONTENT_TYPE}
            </div>
            <div className="filter-container">
              <div
                className={`filter-row-container`}
                role="button"
                onClick={() =>
                  onChanegCheckBox(
                    {
                      target: {
                        checked: !(
                          connectType?.length === connectTypes?.length
                        ),
                      },
                    },
                    DeviceConnectTypes.id
                  )
                }
              >
                <div className="filter-checkbox">
                  <input
                    type="checkbox"
                    checked={connectType?.length === connectTypes?.length}
                    onChange={(e) => onChanegCheckBox(e, DeviceConnectTypes.id)}
                  />
                </div>
                {constants.DEVICES_FILTER_ALL}
              </div>
              {apiConnectTypes?.map((type) => (
                <>
                  {getRowData(
                    DeviceConnectTypes?.[type],
                    DeviceConnectTypes.id
                  )}
                </>
              ))}
            </div>
          </div>
          <div
            className={`filter-column ${
              !fromDiscoveredDevices ? "filter-column-border" : ""
            }`}
          >
            <div className="filter-header">
              {constants.DEVICES_FILTER_STATUS}
            </div>
            <div className="filter-container">
              <div
                className={`filter-row-container`}
                role="button"
                onClick={() =>
                  onChanegCheckBox(
                    {
                      target: {
                        checked: !(fromDiscoveredDevices
                          ? connectionStatus?.length === conStatus?.length
                          : connectionStatus?.length === conStatus?.length &&
                            deviceStatus.length === divStatus?.length),
                      },
                    },
                    DeviceConnectionStatusTypes.id
                  )
                }
              >
                <div className="filter-checkbox">
                  <input
                    type="checkbox"
                    checked={
                      fromDiscoveredDevices
                        ? connectionStatus?.length === conStatus?.length
                        : connectionStatus?.length === conStatus?.length &&
                          deviceStatus.length === divStatus?.length
                    }
                    onChange={(e) =>
                      onChanegCheckBox(e, DeviceConnectionStatusTypes.id)
                    }
                  />
                </div>
                {constants.DEVICES_FILTER_ALL}
              </div>
              {getRowData(
                DeviceConnectionStatusTypes.ONLINE,
                DeviceConnectionStatusTypes.id
              )}
              {getRowData(
                DeviceConnectionStatusTypes.OFFLINE,
                DeviceConnectionStatusTypes.id
              )}
              {!fromDiscoveredDevices ? (
                <>
                  {getRowData(DeviceTypes.DEACTIVATED, DeviceTypes.id)}
                  {getRowData(DeviceTypes.CLAIMING, DeviceTypes.id)}
                </>
              ) : null}
            </div>
          </div>
          {!fromDiscoveredDevices ? (
            <>
              <div className="filter-column filter-column-border">
                <div className="filter-header">
                  {constants.DEVICES_FILTER_FIRMWARE_UPDATE}
                </div>
                <div className="filter-container">
                  <div
                    className={`filter-row-container`}
                    role="button"
                    onClick={() =>
                      onChanegCheckBox(
                        {
                          target: {
                            checked: !(
                              firmwareUpdate?.length === firmwareStatus?.length
                            ),
                          },
                        },
                        DeviceFWTypes.id
                      )
                    }
                  >
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        checked={
                          firmwareUpdate?.length === firmwareStatus?.length
                        }
                        onChange={(e) => onChanegCheckBox(e, DeviceFWTypes.id)}
                      />
                    </div>
                    {constants.DEVICES_FILTER_ALL}
                  </div>
                  {getRowData(DeviceFWTypes.AVAILABLE, DeviceFWTypes.id)}
                  {getRowData(DeviceFWTypes.NOTAVAILABLE, DeviceFWTypes.id)}
                </div>
              </div>
              <div className="filter-column">
                <div className="filter-header">
                  {constants.DEVICES_FILTER_APPLICATIONS_LINKED}
                </div>
                <div className="filter-container">
                  <div
                    className={`filter-row-container`}
                    role="button"
                    onClick={() =>
                      onChanegCheckBox(
                        {
                          target: {
                            checked: !(
                              applicationsLinked?.length === apps?.length
                            ),
                          },
                        },
                        DeviceAppsTypes.id
                      )
                    }
                  >
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        checked={applicationsLinked?.length === apps?.length}
                        onChange={(e) =>
                          onChanegCheckBox(e, DeviceAppsTypes.id)
                        }
                      />
                    </div>
                    {constants.DEVICES_FILTER_ALL}
                  </div>
                  {getRowData(DeviceAppsTypes.ONCLOUD, DeviceAppsTypes.id)}
                  {getRowData(DeviceAppsTypes.SIGHTMIND, DeviceAppsTypes.id)}
                  {getRowData(DeviceAppsTypes.DMPRO, DeviceAppsTypes.id)}
                  {getRowData(DeviceAppsTypes.FLEXAI, DeviceAppsTypes.id)}
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="filter-footer">
          <div
            className="left-section"
            role="button"
            onClick={() => onClickClear()}
          >
            <IoReload color="#0000007D" className="rotate-reload" />
            {constants.DEVICES_MODAL_CLEAR_ALL}
          </div>
          <div className="right-section">
            <PrimaryButton
              className="device-btn-list btn-primary"
              type="button"
              width="100px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--primary_40")}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--brand_white")}
              fontSize="14px"
              height="32px"
              fontWeight="400"
              borderRadius="4px"
              onClick={() => onClickOk()}
            >
              {constants.DEVICES_MODAL_OK}
            </PrimaryButton>
            <PrimaryButton
              className="device-btn-list"
              type="button"
              width="100px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--custom_color_31")}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--custom_color_31")}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--custom_color_31")}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--custom_color_31")}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--greyscale_00")}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--greyscale_00")}
              fontSize="14px"
              height="32px"
              fontWeight="400"
              borderRadius="4px"
              onClick={() => closeModal()}
            >
              {constants.DEVICES_MODAL_CLOSE}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevicesFilters;
