import { PrimaryButton, SiteModal } from "../../../../../components/common";
import { constants } from "../../../../../helpers";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { HiOutlineChevronDown } from "react-icons/hi";

const SetAreaModal = ({
  onHideModal,
  onOkBtnClick,
  gatewayLocationAreas,
  defaultGatewayArea,
}) => {
  const [selectedAreaId, setSelectedAreaId] = useState(
    defaultGatewayArea?.areaId
  );

  const onSelectLocationAreaHandler = (areaId) => {
    if (!areaId) return;
    setSelectedAreaId(areaId);
  };

  const locationDropdownContent =
    gatewayLocationAreas?.length > 0 ? (
      gatewayLocationAreas?.map((area) => (
        <Dropdown.Item
          key={`location-${area.areaId}`}
          className={`area-selector-menu-item`}
          eventKey={area.areaId}
        >
          {area.areaName}
        </Dropdown.Item>
      ))
    ) : (
      <Dropdown.Item
        key={`location-${0}`}
        className={`area-selector-menu-item`}
        eventKey={undefined}
      >
        {constants.LOCATION_DROPDOWN_NO_AREA_DATA_TEXT}
      </Dropdown.Item>
    );

  return (
    <SiteModal
      modalTitle={constants.DEVICES_MODAL_SET_AREA_POPUP_TITLE}
      showModal={true}
      size={"md"}
      hideModal={() => {
        onHideModal();
      }}
      classes="gateway-operations-popup"
      key="authenticate-channel-modal"
      modalFooter={
        <>
          <PrimaryButton
            className={`btn-primary`}
            type="submit"
            width="100px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue("--primary_40")}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              "--brand_white"
            )}
            fontSize="14px"
            height="32px"
            fontWeight="400"
            borderRadius="4px"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onOkBtnClick(selectedAreaId);
            }}
          >
            {constants.SCAN_NETWORK_OK_BTN_LABEL}
          </PrimaryButton>
          <div
            className="primary-btn cancel-btn"
            role="button"
            onClick={() => onHideModal()}
          >
            {constants.SCAN_NETWORK_CANCEL_BTN_LABEL}
          </div>
        </>
      }
    >
      <div className="sub-title mb-4">
        {constants.SCAN_NETWORK_SET_AREA_SUB_TITLE}
      </div>
      <div className="main-area-container">
        <div className="area-label">
          {constants.SCAN_NETWORK_SET_AREA_DROPDOWN_LABEL}
        </div>
        <div className="area-dropdown">
          <Dropdown
            className="area-selector bg-white"
            onSelect={(e) => onSelectLocationAreaHandler(e)}
          >
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-basic"
              className="area-selector-toggle"
            >
              <div className="area-selected-wrapper">
                <span className="area-selected-name">
                  {
                    gatewayLocationAreas?.find(
                      (x) => x.areaId === selectedAreaId
                    )?.areaName
                  }
                </span>
              </div>
              <HiOutlineChevronDown size={20} color="#747E87" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="area-selector-menu">
              {locationDropdownContent}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </SiteModal>
  );
};

export default SetAreaModal;
