import { Form, Formik } from "formik";
import { PrimaryButton, SiteModal } from "../../../../../components/common";
import { TextField } from "../../../../../components/forms";
import { constants, Utils } from "../../../../../helpers";
import { useCallback, useEffect, useRef, useState } from "react";
import { devicesMQTTStore } from "../../../../../store/DevicesMQTTStore";
import { useLoggedInUserData } from "../../../../../store/LoggedInAccountStore";
import * as Yup from "yup";
import { FaTimesCircle } from "react-icons/fa";
import { ReactComponent as PasswordViewHide } from "../../../../../assets/images/view-hide.svg";
import { mqttPublish } from "../../../../../utils/connection/mqttConnection";

const AuthenticateModal = ({
  channelsToBeAuthenticated,
  hubId,
  onHideModal,
  onOkBtnClick,
}) => {
  const formikRef = useRef();
  const resourceList = Utils.getDeviceSettingResource(11);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const sessionId = state.getSessionId();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const tid = Math.floor(new Date().getTime() / 1000.0);
  const accountId = loggedInUserData.accountId;
  const appTopic = `a/notify/${hubId}`;
  const validateNotAuthenticated = Yup.object({
    username: Yup.string().required(constants.USERNAME_ERROR_MESSAGE),
    password: Yup.string().required(constants.PASSWORD_ERROR_MESSAGE),
  });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    formikRef?.current?.resetForm();
    const storedUserName = localStorage.getItem("authUsername");
    const storedPassword = localStorage.getItem("authPassword");
    const storedSaveIdPwd = localStorage.getItem("authSaveIDPwd");
    if (storedSaveIdPwd === "true") {
      formikRef?.current?.setFieldValue("username", storedUserName);
      formikRef?.current?.setFieldValue("password", storedPassword);
      formikRef?.current?.setFieldValue(
        "saveIDPwd",
        storedSaveIdPwd === "true"
      );
    }
  }, []);

  const setPublishRequest = useCallback((resourceURL, propertiesPayload) => {
    if (!accountId) {
      return;
    }
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: hubId,
        from: accountId,
        msg: {
          action: "set",
          resource: resourceURL,
          properties: propertiesPayload,
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      formikRef.current.submitForm();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <SiteModal
      modalTitle={constants.DEVICES_MODAL_AUTHENTICATE_DEVICE}
      showModal={true}
      hideModal={() => {
        onHideModal();
      }}
      classes="gateway-operations-popup"
      key="authenticate-channel-modal"
      modalFooter={
        <>
          <PrimaryButton
            className={`btn-primary`}
            type="submit"
            width="100px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue("--primary_40")}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              "--brand_white"
            )}
            fontSize="14px"
            height="32px"
            fontWeight="400"
            borderRadius="4px"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              formikRef.current.submitForm();
            }}
          >
            {constants.SCAN_NETWORK_OK_BTN_LABEL}
          </PrimaryButton>
          <div
            className="primary-btn cancel-btn"
            role="button"
            onClick={() => onHideModal()}
          >
            {constants.SCAN_NETWORK_CANCEL_BTN_LABEL}
          </div>
        </>
      }
    >
      <Formik
        initialValues={{
          username: "",
          password: "",
          saveIDPwd: false,
        }}
        validationSchema={validateNotAuthenticated}
        onSubmit={(values) => {
          // selectedDeviceLoaderHandler(true);
          if (values.saveIDPwd) {
            localStorage.setItem("authUsername", values.username);
            localStorage.setItem("authPassword", values.password);
            localStorage.setItem("authSaveIDPwd", "true");
          } else {
            localStorage.setItem("authUsername", "");
            localStorage.setItem("authPassword", "");
            localStorage.setItem("authSaveIDPwd", "false");
          }
          channelsToBeAuthenticated.forEach((device) => {
            const properties = {
              channel: device.channel,
              macAddress: device.macAddress,
              un: values.username,
              pw: values.password,
            };
            setPublishRequest(resourceList[1], properties);
          });
          onOkBtnClick();
          // }
          // setTimeout(() => {
          //   selectedDeviceLoaderHandler(false);
          // }, 10000);
        }}
        innerRef={formikRef}
      >
        {({
          errors,
          values,
          dirty,
          isValid,
          handleSubmit,
          handleChange,
          setFieldValue,
        }) => (
          <Form
            className="modal-form authenticate-modal"
            onSubmit={handleSubmit}
          >
            <div className="field-container">
              <div className="label">
                {constants.DEVICES_MODAL_USERNAME_LABEL}
              </div>
              <TextField
                placeholder={constants.DEVICES_MODAL_USERNAME_LABEL}
                name="username"
                type="text"
                removebottommargin="true"
                removetopmargin="true"
                onChange={handleChange}
                afterinputicon={
                  values.username?.length > 0 ? (
                    <FaTimesCircle
                      onClick={() => setFieldValue("username", "")}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </div>
            <div className="field-container">
              <div className="label">
                {constants.DEVICES_MODAL_PASSWORD_LABEL}
              </div>
              <TextField
                placeholder={constants.DEVICES_MODAL_PASSWORD_LABEL}
                name="password"
                type={showPassword ? "text" : "password"}
                removebottommargin="true"
                removetopmargin="true"
                onChange={handleChange}
                afterinputicon={
                  values.password?.length > 0 ? (
                    <>
                      <PasswordViewHide
                        className="password-show-hide"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                      <FaTimesCircle
                        onClick={() => setFieldValue("password", "")}
                      />
                    </>
                  ) : (
                    <></>
                  )
                }
              />
            </div>
            <div className="save-id-pwd">
              <div className="blank-div"></div>
              <div className="save-checkbox-container">
                <input
                  type="checkbox"
                  id="saveIDPwd"
                  name="saveIDPwd"
                  className="form-check-input"
                  checked={values.saveIDPwd}
                  onChange={handleChange}
                />
                <label className="save-check-label" htmlFor="saveIDPwd">
                  {constants.SCAN_NETWORK_SAVE_IDPW_LABEL}
                </label>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </SiteModal>
  );
};

export default AuthenticateModal;
