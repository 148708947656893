import { Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { AppDefaults } from "../../../src/helpers";

function BreadcrumbList({
  isFromOrgDetails = false,
  list,
  isFromEditDevices,
  callBack,
}) {
  let routes;

  if (Array.isArray(list) && list.length > 0) {
    const routeLength = list.length;
    routes = list.map((route, i) => {
      return (
        <Breadcrumb.Item
          key={i}
          linkAs={Link}
          linkProps={{ to: route.url }}
          className={`${i + 1 === routeLength && "active device-name"}`}
          active={route.disable}
          onClick={() => {
            if (isFromEditDevices) {
              callBack();
            }
          }}
        >
          {route.title}
        </Breadcrumb.Item>
      );
    });
  }

  return (
    <div
      className={` ${
        isFromOrgDetails
          ? process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
            ? "org-details-site-breadcrumbs-custom"
            : "org-details-site-breadcrumbs"
          : "site-breadcrumbs desktop"
      }`}
    >
      <Breadcrumb className="parent-device-name">{routes}</Breadcrumb>
    </div>
  );
}

export default BreadcrumbList;
