import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { HiOutlineChevronDown } from "react-icons/hi";
import { useSelector } from "react-redux";
import { constants } from "../../../helpers";
import { getLocationsData } from "../../../store/reducers/AccountReducer";
import { PrimaryButton } from "../../../components/common";
import { Radio, RadioGroup } from "@mui/material";
import "./DiscoveredDevices.scss";
import { getAreasByLocationId } from "./DevicesAPI";

const DiscoveredDevicesRegister = ({
  closeModal,
  onRegisterDevices,
  isD2CInSelectedDevices,
  isToggleClaim,
  selectedDevices,
  isAddAllSelected,
}) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");
  const locationDetails = useSelector(getLocationsData);

  const [selectedLocation, setSelectedLocation] = useState(
    locationDetails.find((location) => location.isDefault)
  );
  const getDefaultSelectedArea = () => {
    const defaultLocation = locationDetails.find(
      (location) => location?.isDefault
    );
    return defaultLocation?.areas?.find((area) => area?.isDefault);
  };
  const [locationAreas, setLocationAreas] = useState(
    locationDetails.find((location) => location.isDefault).areas
  );
  const [selectedArea, setSelectedArea] = useState(getDefaultSelectedArea());
  const [reclaimOption, setReclaimOption] = useState(
    constants.DISCOVERED_DEVICES_RECLAIM
  );

  const getLocationData = (locationId) => {
    if (!locationId) return;

    return locationDetails.find(
      (location) => location.locationId === locationId
    );
  };

  useEffect(() => {
    if (selectedLocation?.locationId) {
      fetchAreasbyLocationId(selectedLocation);
    }
  }, [selectedLocation?.locationId]);

  const getDefaultSelectedAreaFromList = (areas) => {
    return areas?.find((area) => area?.isDefault);
  };

  const fetchAreasbyLocationId = async (locationData) => {
    let areaAPIResponse = await getAreasByLocationId(orgId, locationData);
    if (areaAPIResponse?.length) {
      setLocationAreas(areaAPIResponse);
      setSelectedArea(getDefaultSelectedAreaFromList(areaAPIResponse));
    }
  };
  const onSelectLocationHandler = (evtKey) => {
    if (!evtKey) return;

    const locationData = getLocationData(evtKey);
    setSelectedLocation(locationData);
    setLocationAreas(locationData?.areas);
    setSelectedArea(getDefaultSelectedAreaFromList(locationData?.areas));
  };

  const onSelectAreaHandler = (evtKey) => {
    if (!evtKey) return;
    let selectedArea = locationAreas.find((area) => area.areaId === evtKey);
    setSelectedArea(selectedArea);
  };

  const onClickOkay = () => {
    onRegisterDevices(selectedLocation, selectedArea, reclaimOption);
    closeModal();
  };

  const locationDropdownContent =
    locationDetails?.length > 0 ? (
      locationDetails?.map((location) => (
        <Dropdown.Item
          key={`location-${location.locationId}`}
          className={`area-selector-menu-item ${
            selectedLocation?.locationId === location.locationId
              ? "selected"
              : ""
          }`}
          eventKey={location.locationId}
        >
          {location.locationName}
        </Dropdown.Item>
      ))
    ) : (
      <Dropdown.Item
        key={`location-${0}`}
        className={`area-selector-menu-item`}
        eventKey={undefined}
      >
        {constants.LOCATION_DROPDOWN_NO_LOCATION_DATA_TEXT}
      </Dropdown.Item>
    );

  const areaDropdownContent =
    locationAreas?.length > 0 ? (
      locationAreas?.map((area) => (
        <Dropdown.Item
          key={`area-${area.areaId}`}
          className={
            `area-selector-menu-item` +
            (selectedArea?.areaId === area.areaId ? " selected" : "")
          }
          eventKey={area.areaId}
        >
          {area.areaName}
        </Dropdown.Item>
      ))
    ) : (
      <Dropdown.Item
        key={`area-${0}`}
        className={`area-selector-menu-item`}
        eventKey={undefined}
      >
        {constants.LOCATION_DROPDOWN_NO_AREA_DATA_TEXT}
      </Dropdown.Item>
    );

  return (
    <div className="location-area-selector-modal-wrapper">
      <div className="modal-title">
        {isToggleClaim ? (
          <>{constants.DEVICES_MODAL_SET_LOCATION_DESC_CLAIM}</>
        ) : (
          <>{constants.DEVICES_MODAL_SET_LOCATION_DESC_RECLAIM}</>
        )}
      </div>
      {!isToggleClaim ? (
        <>
          <div className="d-flex mt-4">
            <RadioGroup
              aria-labelledby="base-details-radio-buttons"
              name="controlled-radio-buttons-group"
            >
              <Radio
                sx={{
                  color: getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--greyscale_80"),
                }}
                checked={reclaimOption === constants.DISCOVERED_DEVICES_RECLAIM}
                onChange={(e) => {
                  setReclaimOption(constants.DISCOVERED_DEVICES_RECLAIM);
                }}
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
            </RadioGroup>

            <div className="mx-2">
              <div className="device-setup-modal-subTitle">
                {constants.DISCOVERED_DEVICES_RECLAIM_OPT1_TITLE}
              </div>
              <div className="discription mt-1">
                {constants.DISCOVERED_DEVICES_RECLAIM_OPT1_INFO}
              </div>
            </div>
          </div>
          <div className="d-flex mt-4 mb-2">
            <RadioGroup
              aria-labelledby="base-details-radio-buttons"
              name="controlled-radio-buttons-group"
            >
              <Radio
                sx={{
                  color: getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--greyscale_80"),
                }}
                checked={reclaimOption === constants.DISCOVERED_DEVICES_ADD}
                onChange={(e) => {
                  setReclaimOption(constants.DISCOVERED_DEVICES_ADD);
                }}
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
            </RadioGroup>
            <div className="mx-2">
              <div className="device-setup-modal-subTitle">
                {constants.DISCOVERED_DEVICES_RECLAIM_OPT2_TITLE}
              </div>
              <div className="discription mt-1">
                {constants.DISCOVERED_DEVICES_RECLAIM_OPT2_INFO}
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="dropdown-title">
        {!isD2CInSelectedDevices && !isAddAllSelected ? (
          <>{constants.DEVICES_LIST_LOCATION}</>
        ) : (
          <>{constants.DEVICES_LIST_LOCATION_N_AREA}</>
        )}
      </div>
      <div className="dropdown-container">
        <div
          className={`dropdown-row ${
            isD2CInSelectedDevices && selectedDevices?.length ? "pb-3" : ""
          }`}
        >
          {constants.DEVICES_MODAL_LOCATION}

          <Dropdown
            className="area-selector mr-3"
            onSelect={onSelectLocationHandler}
          >
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-location"
              className="area-selector-toggle"
              disabled={
                isD2CInSelectedDevices &&
                selectedDevices?.length &&
                !isAddAllSelected
              }
            >
              <div className="area-selected-wrapper">
                <span className="area-selected-name">
                  {selectedLocation?.locationName}
                </span>
              </div>
              <HiOutlineChevronDown size={20} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="area-selector-menu">
              {locationDropdownContent}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {isD2CInSelectedDevices || !selectedDevices?.length ? (
          <div className="dropdown-row pt-3 row-border">
            {constants.DEVICES_MODAL_AREA}
            <Dropdown className="area-selector" onSelect={onSelectAreaHandler}>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-area"
                className="area-selector-toggle"
              >
                <div className="area-selected-wrapper">
                  <span className="area-selected-name">
                    {selectedArea?.areaName}
                  </span>
                </div>
                <HiOutlineChevronDown size={20} />
              </Dropdown.Toggle>
              <Dropdown.Menu className="area-selector-menu">
                {areaDropdownContent}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}
      </div>
      <div className="button-container">
        <PrimaryButton
          className="device-btn-list btn-primary"
          type="button"
          width="100px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--primary_40")}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            "--brand_white"
          )}
          fontSize="14px"
          height="32px"
          fontWeight="400"
          borderRadius="4px"
          onClick={() => onClickOkay()}
        >
          {constants.DEVICES_MODAL_OK}
        </PrimaryButton>

        <PrimaryButton
          className="device-btn-list"
          type="button"
          width="100px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            "--greyscale_00"
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--greyscale_00")}
          fontSize="14px"
          height="32px"
          fontWeight="400"
          borderRadius="4px"
          onClick={() => closeModal()}
        >
          {constants.DEVICES_MODAL_CANCEL}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default DiscoveredDevicesRegister;
