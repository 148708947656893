import { useCallback, useEffect, useRef, useState } from "react";
import { sassTrue } from "sass";
import { useSelector } from "react-redux";
import { TfiReload } from "react-icons/tfi";
import { OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { RxCaretSort } from "react-icons/rx";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import { CircleExclamation, DefaultDevice } from "../../../assets/images";
import "./ScanNetworkModal.scss";
import {
  AppDefaults,
  constants,
  DeviceTypeEnum,
  Utils,
} from "../../../helpers";
import ResizableTable from "./ResizableTable";
import { SiteSpinner } from "../../../components/common";
import { useLoggedInUserData } from "../../../store/LoggedInAccountStore";
import { devicesMQTTStore } from "../../../store/DevicesMQTTStore";
import { mqttPublish } from "../../../utils/connection/mqttConnection";
import { getAllMqttDataFromResponse } from "../../../store/reducers/AccountReducer";
import { getOrgInfo } from "../../../store/reducers/OrganizationsReducer";
import {
  getGatewayDeviceCountAPI,
  moveDeviceToHubAPI,
} from "./scanNetwork/helper/ScanNetworkAPIHelper";
import AuthenticateModal from "./scanNetwork/components/AuthenticateModal";
import { getManufacturerSKUList } from "../../../store/reducers/NVRDeviceReducer";
import { fetchDevicesBysNOMacAPI, getAreasByLocationId } from "./DevicesAPI";
import { GoPencil } from "react-icons/go";
import { IoCloseCircle } from "react-icons/io5";
import ScanNetworkModalWithArea from "./ScanNetworkModalWithArea";
import SetAreaModal from "./scanNetwork/components/SetAreaModal";
import { addDeviceToHubAPI } from "../addDevice/addDeviceAPI";
import MoveModal from "./scanNetwork/components/MoveModal";
import { HiOutlinePlus } from "react-icons/hi2";
import { TbMapPin } from "react-icons/tb";

const ScanNetworkModal = ({ gatewayDeviceData }) => {
  const defaultPageDetails = {
    orderBy: "deviceName",
    isAsc: true,
  };

  const paginatedHeaderList = [
    {
      displayName: "",
      id: "checkbox",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_NAME,
      id: "deviceName",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_MODEL,
      id: "modelNumber",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_SN,
      id: "serialNumber",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_IP,
      id: "ipAddress",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_PORT,
      id: "port",
    },
  ];

  const orgInfo = useSelector(getOrgInfo);
  const tabList = Utils.getScanNetworkTabs(gatewayDeviceData?.deviceType);
  const [selectedTabKey, setSelectedTabKey] = useState(tabList[0]);
  const [selectedChannelCount, setSelectedChannelCount] = useState(0);
  const [pageDetails, setPageDetails] = useState(defaultPageDetails);
  const [scannedDevicesTree, setScannedDevicesTree] = useState([]);
  const [allScannedDevicesList, setAllScannedDevicesList] = useState([]);
  const [filteredChannelsByTab, setFilteredChannelsByTab] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [timerId, setTimerId] = useState(null);
  const [deviceCountData, setDeviceCountData] = useState({
    deviceCount: "-",
    totalCapacity: "-",
  });
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const sessionId = state.getSessionId();
  const resourceList = Utils.getDeviceSettingResource(11);
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const tid = Math.floor(new Date().getTime() / 1000.0);
  const accountId = loggedInUserData.accountId;
  const hubId = gatewayDeviceData?.gatewayId;
  const appTopic = `a/notify/${hubId}`;
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [showAuthenticateModal, setShowAuthenticateModal] = useState(false);
  const [showSetAreaModal, setShowSetAreaModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const manufacturerSKUList = useSelector(getManufacturerSKUList);
  const [gatewayLocationAreas, setGatewayLocationAreas] = useState([]);
  const defaultGatewayAreaRef = useRef({
    areaId: "",
    areaName: "",
  });
  const [renamingDevice, setRenamingDevice] = useState(null);
  const [failedAuthDevices, setFailedAuthDevices] = useState([]);
  const [passedAuthDevices, setPassedAuthDevices] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    fetchAreasbyLocationId();
    fetchGatewayDeviceCount();
    getPublishRequest();
    showLoaderAndSetTimerId(
      AppDefaults.SCAN_NETWORK_HIDE_LOADER_TIMEOUT_IN_MILLISEC
    );
    Utils.vmsLogger().log("hubId", hubId);

    return () => {
      clearPassFailedDevices();
    };
  }, []);

  useEffect(() => {
    setSelectedChannelCount(selectedChannels?.length || 0);
  }, [selectedChannels]);

  useEffect(() => {
    const updatedList = allScannedDevicesList.map((item) => {
      const foundItem = passedAuthDevices.find(
        (x) => x.channel === item.channel
      );
      return foundItem
        ? {
            ...item,
            authStatus: true,
            serialNumber: foundItem?.serialNumber,
            macAddress: foundItem?.macAddress?.toUpperCase(),
            manufacturer: item?.manufacturer,
          }
        : item;
    });
    setAllScannedDevicesList(updatedList);
    if (passedAuthDevices?.length > 0) {
      updateDeviceTree();
    }
  }, [passedAuthDevices?.length]);

  useEffect(() => {
    const list = getFilteredListBySelectTab(allScannedDevicesList);
    setFilteredChannelsByTab(list);
    setSelectedChannels([]);
  }, [allScannedDevicesList, selectedTabKey, pageDetails]);

  const getPublishRequest = useCallback(() => {
    if (!accountId) {
      return;
    }
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: hubId,
        from: accountId,
        msg: {
          action: "get",
          resource:
            gatewayDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE
              ? resourceList[2].replace("{waveID}", gatewayDeviceData.deviceId)
              : resourceList[0],
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  }, []);

  useEffect(() => {
    const mqttResponseHandler = async () => {
      const resource = allMqttData?.msg?.resource;
      if (resource?.includes("hub/channels/auth-creds")) {
        const properties = { ...allMqttData?.msg?.properties };
        Utils.vmsLogger().log("Authenticate Device Status", properties);
        if (!properties?.device?.authStatus) {
          const authenticateDevice = properties?.device;
          const failedChannels = [];
          authenticateDevice?.channels?.forEach((channel) => {
            if (
              !channel.authStatus &&
              failedAuthDevices.findIndex(
                (x) => x.channel === channel.channel
              ) === -1
            ) {
              failedChannels.push(channel);
            }
          });
          setFailedAuthDevices([...failedAuthDevices, ...failedChannels]);
          const updatedList = scannedDevicesTree.map((x) => {
            return x.channel === authenticateDevice?.channel
              ? { ...x, serialNumber: authenticateDevice.serialNumber }
              : x;
          });
          setScannedDevicesTree(updatedList);
        } else {
          const allChannels = properties?.device?.channels || [];
          const passedChannels = [];
          allChannels.forEach((channel) => {
            if (
              channel.authStatus &&
              passedAuthDevices.findIndex(
                (x) => x.channel === channel.channel
              ) === -1
            ) {
              passedChannels.push(channel);
            }
          });
          setPassedAuthDevices([...passedAuthDevices, ...passedChannels]);
        }
        hideLoaderAndClearTimerId();
      } else if (resource?.includes("hub/channels")) {
        const properties = { ...allMqttData?.msg?.properties };
        // TODO: To Be Removed
        Utils.vmsLogger().log("Scanned Channel List", properties?.devices);
        if (properties?.devices?.length > 0) {
          const allPropsDevices = properties?.devices;
          const parentDeviceList = allPropsDevices?.map((d) => d.device);
          setScannedDevicesTree(parentDeviceList);
          let channelsForGateway = structuredClone(parentDeviceList);
          const childDeviceList = getChannelsList(parentDeviceList);
          if (
            gatewayDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR
          ) {
            channelsForGateway = structuredClone(childDeviceList);
          }
          const parentChildDeviceList = [
            ...parentDeviceList,
            ...childDeviceList,
          ];
          const macAndSerialList = parentChildDeviceList
            .map((device) => ({
              macAddress: device.macAddress?.toUpperCase(),
              serialNo: device.serialNumber,
            }))
            .filter((x) => x.macAddress && x.serialNo);
          fetchDevicesDetailsBySNoAndMac(
            macAndSerialList,
            channelsForGateway,
            parentDeviceList
          );
        } else {
          hideLoaderAndClearTimerId();
        }
      } else if (
        resource?.includes(`ch/${gatewayDeviceData.deviceId}/scan-nw`)
      ) {
        const properties = { ...allMqttData?.msg?.properties };
        // TODO: To Be Removed
        Utils.vmsLogger().log(
          "Scanned Channel List For Wave",
          properties?.devices
        );
        if (properties?.devices?.length > 0) {
          const allPropsDevices = properties?.devices;
          const parentDeviceList = allPropsDevices?.map((d) => d.device);
          setScannedDevicesTree(parentDeviceList);
          const childDeviceList = getChannelsList(parentDeviceList);
          const parentChildDeviceList = [
            ...parentDeviceList,
            ...childDeviceList,
          ];
          const macAndSerialList = parentChildDeviceList
            .map((device) => ({
              macAddress: device.macAddress?.toUpperCase(),
              serialNo: device.serialNumber,
            }))
            .filter((x) => x.macAddress && x.serialNo);
          fetchDevicesDetailsBySNoAndMac(
            macAndSerialList,
            parentDeviceList,
            parentDeviceList
          );
        } else {
          hideLoaderAndClearTimerId();
        }
      }
    };

    mqttResponseHandler();
  }, [allMqttData]);

  const getChannelsList = (data) => {
    const list = [];
    data.forEach((d) => {
      d.channels && list.push(...d.channels);
    });
    return list;
  };

  const fetchGatewayDeviceCount = async () => {
    const requestId =
      gatewayDeviceData?.deviceType === DeviceTypeEnum.WAVE
        ? gatewayDeviceData?.deviceId
        : hubId;
    const response = await getGatewayDeviceCountAPI(orgInfo?.orgId, requestId);
    if (response) {
      setDeviceCountData({
        deviceCount: response.deviceCount,
        totalCapacity: response.totalCapacity,
      });
    } else {
      setDeviceCountData({ deviceCount: "-", totalCapacity: "-" });
    }
  };

  const fetchAreasbyLocationId = async () => {
    const locationData = { locationId: gatewayDeviceData?.locationId };
    const areaAPIResponse = await getAreasByLocationId(
      orgInfo.orgId,
      locationData
    );
    if (areaAPIResponse?.length > 0) {
      setGatewayLocationAreas(areaAPIResponse);
      const defaultArea = areaAPIResponse?.find((x) => x.isDefault);
      defaultGatewayAreaRef.current = {
        areaId: defaultArea?.areaId || "",
        areaName: defaultArea?.areaName || "",
      };
    } else {
      defaultGatewayAreaRef.current = {
        areaId: "",
        areaName: "",
      };
      setGatewayLocationAreas([]);
    }
  };

  const updateDeviceTree = () => {
    const groupedByMacAddress = passedAuthDevices.reduce((acc, device) => {
      const { macAddress } = device;
      if (!acc[macAddress.toUpperCase()]) {
        acc[macAddress.toUpperCase()] = [];
      }
      acc[macAddress.toUpperCase()].push(device);
      return acc;
    }, {});
    const updatedTreeList = scannedDevicesTree.map((item) => {
      const foundItem = groupedByMacAddress[item.macAddress?.toUpperCase()];
      return foundItem
        ? {
            ...item,
            channels: item.channels.map((x) => {
              const foundCh = foundItem.find((ch) => ch.channel === x.channel);
              return foundCh
                ? {
                    ...x,
                    serialNumber: foundCh.serialNumber,
                    authStatus: foundCh.authStatus,
                  }
                : x;
            }),
          }
        : item;
    });
    setScannedDevicesTree(updatedTreeList);
    if (gatewayDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR) {
      const childDeviceList = getChannelsList(updatedTreeList);
      const channelsForGateway = structuredClone(childDeviceList);
      const parentChildDeviceList = [...updatedTreeList, ...childDeviceList];
      const macAndSerialList = parentChildDeviceList
        .map((device) => ({
          macAddress: device.macAddress?.toUpperCase(),
          serialNo: device.serialNumber,
        }))
        .filter((x) => x.macAddress);
      fetchDevicesDetailsBySNoAndMac(
        macAndSerialList,
        channelsForGateway,
        updatedTreeList
      );
    } else {
      const childDeviceList = getChannelsList(updatedTreeList);
      const parentChildDeviceList = [...updatedTreeList, ...childDeviceList];
      const macAndSerialList = parentChildDeviceList
        .map((device) => ({
          macAddress: device.macAddress?.toUpperCase(),
          serialNo: device.serialNumber,
        }))
        .filter((x) => x.macAddress);
      fetchDevicesDetailsBySNoAndMac(
        macAndSerialList,
        updatedTreeList,
        updatedTreeList
      );
    }
  };

  const getAreaDetailsById = (areaId) => {
    const foundArea = gatewayLocationAreas.find((x) => x.areaId === areaId);
    return foundArea
      ? { areaId: foundArea.areaId, areaName: foundArea.areaName }
      : defaultGatewayAreaRef.current;
  };

  const fetchDevicesDetailsBySNoAndMac = async (
    payload,
    channelsForGateway,
    parentDeviceList
  ) => {
    try {
      if (!payload || payload?.length === 0) {
        return;
      }
      const responseData = await fetchDevicesBysNOMacAPI(
        orgInfo.orgId,
        payload
      );
      const devicesWithName = responseData?.devices || [];
      let updatedList = [];
      if (devicesWithName.length === 0) {
        updatedList = channelsForGateway.map((item) => ({
          ...item,
          ...defaultGatewayAreaRef.current,
          deviceName: item.deviceName || item.serialNumber,
        }));
      } else {
        const deviceMap = new Map(
          devicesWithName.map((device) => [
            `${device.macAddress?.toUpperCase()}-${device.serialNo}`,
            device,
          ])
        );
        const allDeviceMap = new Map(
          allScannedDevicesList.map((device) => [
            `${device.macAddress?.toUpperCase()}-${device.serialNo}`,
            device,
          ])
        );

        const getDeviceDetails = (item) => {
          const foundDevice = deviceMap?.get(
            `${item.macAddress?.toUpperCase()}-${item.serialNumber}`
          );
          if (foundDevice) {
            const childUpdatedList = item?.channels?.map(getDeviceDetails);
            const foundMainDevice = allDeviceMap?.get(
              `${item.macAddress?.toUpperCase()}-${item.serialNumber}`
            );
            return {
              ...item,
              ...foundDevice,
              ...getAreaDetailsById(foundDevice.areaId),
              deviceName:
                foundMainDevice?.deviceName ||
                foundDevice?.deviceName ||
                item?.deviceName,
              channels: childUpdatedList || [],
            };
          }
          return {
            ...item,
            ...defaultGatewayAreaRef.current,
            deviceName: item?.deviceName || item.serialNumber,
          };
        };

        updatedList = channelsForGateway.map(getDeviceDetails);
        const updatedTreeList = parentDeviceList.map(getDeviceDetails);
        setScannedDevicesTree(updatedTreeList);
      }
      setTimeout(() => {
        // Calling device count api after 1 sec so that we can get updated added device count.
        // If still we not get updated info then need to click on Reload button
        fetchGatewayDeviceCount();
      }, 1000);
      setAllScannedDevicesList(updatedList);
      hideLoaderAndClearTimerId();
    } catch (error) {
      hideLoaderAndClearTimerId();
      Utils.vmsLogger().error("ERROR: ", error);
    }
  };

  const hideLoaderAndClearTimerId = () => {
    if (timerId) {
      clearTimeout(timerId);
    }
    setShowLoader(false);
  };

  const showLoaderAndSetTimerId = (timeInMiliSec) => {
    const id = setTimeout(() => {
      setShowLoader(false);
    }, timeInMiliSec);
    setTimerId(id);
    setShowLoader(true);
  };

  const getFilteredListBySelectTab = (list) => {
    let filteredData = [];
    if (selectedTabKey === constants.SCAN_NETWORK_UNAUTHENTICATE_TAB) {
      filteredData = list.filter((x) => !x.authStatus);
    } else if (selectedTabKey === constants.SCAN_NETWORK_ALREADY_ASSIGNED_TAB) {
      filteredData = list.filter(
        (x) =>
          x.gatewayId !== hubId &&
          x.deviceStatus !== constants.DEVICES_DEACTIVATED_DEVICE_STATUS &&
          x.deviceId
      );
    } else {
      filteredData = list.filter(
        (x) =>
          x.authStatus &&
          x.gatewayId !== hubId &&
          x.deviceStatus !== constants.DEVICES_CLAIMED_DEVICE_STATUS &&
          x.deviceStatus !== constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS
      );
    }
    const sortedData = filteredData.sort((a, b) => {
      if (a[pageDetails.orderBy] < b[pageDetails.orderBy])
        return pageDetails.isAsc ? -1 : 1;
      if (a[pageDetails.orderBy] > b[pageDetails.orderBy])
        return pageDetails.isAsc ? 1 : -1;
      return 0;
    });
    return sortedData;
  };

  const onChangeCheckBox = (e, device) => {
    clearPassFailedDevices();
    const { checked } = e.target;
    if (device === "all") {
      if (checked) {
        setSelectedChannels(filteredChannelsByTab);
      } else {
        setSelectedChannels([]);
      }
    } else {
      let checkList = structuredClone(selectedChannels);
      if (checked) {
        checkList.push(device);
      } else {
        checkList = checkList.filter((x) => x.channel !== device?.channel);
      }
      setSelectedChannels([...new Set(checkList)]);
    }
  };

  const getChannelPayload = (device, deviceType) => {
    const manufacturer = device?.manufacturer || "";
    return {
      channel: device?.channel,
      serialNo: device?.serialNumber || device?.serialNo,
      macAddress: device?.macAddress?.toUpperCase(),
      manufacturer:
        deviceType === DeviceTypeEnum.DMSERVER
          ? manufacturer
          : manufacturer ||
            manufacturerSKUList?.find(
              (item) => item?.model === device?.modelNumber
            )?.manufacturer,
      model: device?.modelNumber,
      locationId: gatewayDeviceData?.locationId,
      timezone: gatewayDeviceData?.properties?.["timezone"],
      deviceType: device?.deviceType || "onvifcam",
      requestedApps: [],
      chIndex: device?.chIndex,
    };
  };

  const handleTabChange = (tab) => {
    setSelectedTabKey(tab);
    clearPassFailedDevices();
  };

  const handleSetAreaBtnClick = () => {
    setShowSetAreaModal(true);
  };

  const handleMoveBtnClick = () => {
    setShowMoveModal(true);
  };

  const handleAuthenticateBtnClick = () => {
    setShowAuthenticateModal(true);
  };

  const handleAddBtnClick = async () => {
    if (showLoader) return;
    let payload = [];
    setShowLoader(true);
    if (gatewayDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR) {
      const parentDevicesList = [];
      selectedChannels.forEach((selectedChannel) => {
        const selectedMac = selectedChannel.macAddress?.toUpperCase();
        scannedDevicesTree.forEach((item) => {
          const parentMac = item.macAddress?.toUpperCase();
          if (selectedMac === parentMac) {
            const parentIndex = parentDevicesList.findIndex(
              (x) => x.macAddress?.toUpperCase() === parentMac
            );
            if (parentIndex === -1) {
              parentDevicesList.push({ ...item, channels: [selectedChannel] });
            } else {
              const parent = parentDevicesList[parentIndex];
              parent.channels.push(selectedChannel);
            }
          }
        });
      });
      payload = parentDevicesList.map((parentDevice) => {
        return {
          ...getChannelPayload(parentDevice),
          deviceName: parentDevice.deviceName || parentDevice.serialNumber,
          areaId: parentDevice.areaId || defaultGatewayAreaRef.current.areaId,
          channels:
            parentDevice?.channels?.map((channel) => {
              return {
                ...getChannelPayload(channel),
                deviceName: channel.deviceName || channel.serialNumber,
                areaId: channel.areaId,
              };
            }) || [],
        };
      });
    } else if (
      gatewayDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE
    ) {
      const channels = selectedChannels.map((item) => {
        return {
          ...getChannelPayload(
            item,
            gatewayDeviceData?.deviceType?.toUpperCase()
          ),
          deviceName: item.deviceName || item.serialNumber,
          areaId: item.areaId,
          channels:
            item?.channels?.map((channel) => {
              return {
                ...getChannelPayload(
                  channel,
                  gatewayDeviceData?.deviceType?.toUpperCase()
                ),
                deviceName: channel.deviceName || channel.serialNumber,
                areaId: channel.areaId || defaultGatewayAreaRef.current.areaId,
              };
            }) || [],
        };
      });
      payload = [
        {
          deviceName: gatewayDeviceData?.deviceName,
          areaId: gatewayDeviceData?.areaId,
          channels: channels,
          ...getChannelPayload(
            gatewayDeviceData,
            gatewayDeviceData?.deviceType?.toUpperCase()
          ),
        },
      ];
    } else {
      payload = selectedChannels.map((item) => {
        return {
          ...getChannelPayload(
            item,
            gatewayDeviceData?.deviceType?.toUpperCase()
          ),
          deviceName: item.deviceName || item.serialNumber,
          areaId: item.areaId,
          channels:
            item?.channels?.map((channel) => {
              return {
                ...getChannelPayload(
                  channel,
                  gatewayDeviceData?.deviceType?.toUpperCase()
                ),
                deviceName: channel.deviceName || channel.serialNumber,
                areaId: channel.areaId || defaultGatewayAreaRef.current.areaId,
              };
            }) || [],
        };
      });
    }
    Utils.vmsLogger().log("payload", payload);
    const deviceResponse = await addDeviceToHubAPI(orgInfo, payload, hubId);
    if (deviceResponse?.meta?.code === 200) {
      const data = deviceResponse?.data;
      if (data.devices.length > 0) {
        const devicesToUpdate = [];
        const passedChannels = [];
        data.devices.forEach((deviceData) => {
          const device = deviceData.device;
          const channels = deviceData.channels;
          devicesToUpdate.push(...[...devicesToUpdate, device, ...channels]);
          if (
            gatewayDeviceData?.deviceType?.toUpperCase() ===
              DeviceTypeEnum.NVR ||
            gatewayDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE
          ) {
            // SerialNumber is missing in moved device API response.
            // And it is needed while calling search API
            const channelsWithSno = channels.map((ch) => {
              const foundItem = allScannedDevicesList.find(
                (x) => x.channel === ch.channel
              );
              return foundItem
                ? {
                    ...ch,
                    serialNumber: foundItem.serialNumber,
                    authStatus: true,
                  }
                : ch;
            });
            passedChannels.push(...channelsWithSno);
          } else {
            passedChannels.push(device);
          }
        });
        setPassedAuthDevices(passedChannels);
        const updatedList = allScannedDevicesList.map((item) => {
          const foundItem = devicesToUpdate.find(
            (x) => x.channel === item.channel
          );
          return foundItem
            ? {
                ...item,
                ...foundItem,
              }
            : item;
        });
        setAllScannedDevicesList(updatedList);
      }
    } else {
      setErrorMsg(deviceResponse?.userMsg);
    }
    setSelectedChannels([]);
    setShowLoader(false);
  };

  const clearPassFailedDevices = () => {
    setFailedAuthDevices([]);
    setPassedAuthDevices([]);
    setErrorMsg("");
  };

  const handleReloadBtnClick = () => {
    clearPassFailedDevices();
    setSelectedChannels([]);
    showLoaderAndSetTimerId(
      AppDefaults.SCAN_NETWORK_HIDE_LOADER_TIMEOUT_IN_MILLISEC
    );
    getPublishRequest();
  };

  const onSort = (heading) => {
    const rawPageDetails = structuredClone(pageDetails);

    if (rawPageDetails.orderBy === heading?.id) {
      rawPageDetails.isAsc = !pageDetails.isAsc;
    } else {
      rawPageDetails.isAsc = sassTrue;
    }
    rawPageDetails.orderBy = heading?.id;
    setPageDetails(rawPageDetails);
  };

  const hideAuthenticateModalHandler = () => {
    setShowAuthenticateModal(false);
  };

  const okAuthBtnClickHandler = () => {
    if (showLoader) return;
    setSelectedChannels([]);
    setShowAuthenticateModal(false);
    showLoaderAndSetTimerId(
      AppDefaults.SCAN_NETWORK_HIDE_LOADER_TIMEOUT_IN_MILLISEC
    );
  };

  const hideSetAreaModalHandler = () => {
    setShowSetAreaModal(false);
  };

  const okSetAreaBtnClickHandler = (areaId) => {
    if (showLoader) return;
    setShowLoader(true);
    const selectedAreaDetails = getAreaDetailsById(areaId);
    const updatedList = allScannedDevicesList.map((item) => {
      const foundItem = selectedChannels.find(
        (x) => x.channel === item.channel
      );
      return foundItem
        ? {
            ...item,
            ...selectedAreaDetails,
          }
        : item;
    });
    setAllScannedDevicesList(updatedList);
    setSelectedChannels([]);
    setShowSetAreaModal(false);
    setShowLoader(false);
  };

  const hideMoveModalHandler = () => {
    setShowMoveModal(false);
  };

  const okMoveBtnClickHandler = async (payload) => {
    if (showLoader) return;
    setShowLoader(true);
    setShowMoveModal(false);
    const deviceResponse = await moveDeviceToHubAPI(
      orgInfo,
      { devices: payload },
      hubId
    );
    if (deviceResponse?.meta?.code === 200) {
      const data = deviceResponse?.data;
      if (data.devices.length > 0) {
        const devicesToUpdate = [];
        const passedChannels = [];
        data.devices.forEach((deviceData) => {
          const device = deviceData.device;
          const channels = deviceData.channels;
          devicesToUpdate.push(...[...devicesToUpdate, device, ...channels]);
          if (
            gatewayDeviceData?.deviceType?.toUpperCase() ===
              DeviceTypeEnum.NVR ||
            gatewayDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE
          ) {
            // SerialNumber is missing in moved device API response.
            // And it is needed while calling search API
            const channelsWithSno = channels.map((ch) => {
              const foundItem = allScannedDevicesList.find(
                (x) => x.channel === ch.channel
              );
              return foundItem
                ? {
                    ...ch,
                    serialNumber: foundItem.serialNumber,
                    authStatus: true,
                  }
                : ch;
            });
            passedChannels.push(...channelsWithSno);
          } else {
            const foundItem = allScannedDevicesList.find(
              (item) => item.channel === device.channel
            );
            passedChannels.push({
              ...device,
              serialNumber: foundItem.serialNumber,
            });
          }
        });
        // This is used to show Move Device count message
        setPassedAuthDevices(passedChannels);
        const updatedList = allScannedDevicesList.map((item) => {
          const foundItem = devicesToUpdate.find(
            (x) => x.channel === item.channel
          );
          return foundItem
            ? {
                ...item,
                ...foundItem,
              }
            : item;
        });
        setAllScannedDevicesList(updatedList);
      }
    } else {
      setErrorMsg(deviceResponse?.userMsg);
    }
    setSelectedChannels([]);
    setShowLoader(false);
  };

  const onSelectLocationAreaHandler = (event, device) => {
    const areaId = event.target.value;
    if (!areaId) return;
    const updatedList = allScannedDevicesList.map((item) =>
      item.channel === device.channel
        ? { ...item, ...getAreaDetailsById(areaId) }
        : item
    );
    setAllScannedDevicesList(updatedList);
  };

  const onRenameDevice = (newName) => {
    const updatedList = allScannedDevicesList.map((item) =>
      item.channel === renamingDevice.channel
        ? { ...item, deviceName: newName }
        : item
    );
    setAllScannedDevicesList(updatedList);
    const updatedTreeList = scannedDevicesTree.map((item) => {
      return item.channel === renamingDevice?.channel
        ? { ...item, deviceName: newName }
        : item;
    });
    setScannedDevicesTree(updatedTreeList);
    setRenamingDevice(null);
  };

  const displayDeviceNameColumnDetails = (device) => {
    return (
      <>
        {renamingDevice?.channel === device?.channel ? (
          <span className="edit-name-container">
            <input
              autoFocus={true}
              // maxLength={40}
              type={"text"}
              placeholder={"Name"}
              className="rename-input"
              defaultValue={device?.deviceName}
              onBlur={() => {
                setRenamingDevice(null);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onRenameDevice(e.target.value);
                }
              }}
            />
            <IoCloseCircle
              className="cross-icon"
              size={16}
              onClick={() => {
                setRenamingDevice(null);
              }}
              color="#747E87"
              role="button"
            />
          </span>
        ) : (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{device.deviceName || "NA"}</Tooltip>}
          >
            <span className={"searchStyles"}>{device.deviceName || "NA"}</span>
          </OverlayTrigger>
        )}
        {renamingDevice?.channel !== device?.channel &&
        selectedTabKey !== constants.SCAN_NETWORK_ALREADY_ASSIGNED_TAB ? (
          <span className="hover-icons">
            <GoPencil
              size={16}
              color="#0000007D"
              role="button"
              className={"mx-1 mb-1"}
              onClick={() => {
                clearPassFailedDevices();
                setRenamingDevice(device);
              }}
            />
          </span>
        ) : null}
      </>
    );
  };

  const displayDeviceRowDetails = (device) => {
    return (
      <tr className={device?.isExpanded ? "expanded-parent-row" : ""}>
        <td>
          <span className="delete-checkbox">
            <input
              type="checkbox"
              className="delete-checkbox"
              checked={
                selectedChannels.find((x) => x.channel === device.channel) ||
                false
              }
              disabled={
                selectedTabKey ===
                  constants.SCAN_NETWORK_ALREADY_ASSIGNED_TAB &&
                isNvrDeviceCountLimitReached()
              }
              onChange={(e) => onChangeCheckBox(e, device)}
              role="button"
            />
          </span>
        </td>
        <td
          className={
            renamingDevice?.channel === device?.channel
              ? ""
              : "truncated-device-name"
          }
        >
          {displayDeviceNameColumnDetails(device)}
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{device.modelNumber || "-"}</Tooltip>}
          >
            <span className={"searchStyles"}>{device.modelNumber || "-"}</span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{device.serialNumber || "-"}</Tooltip>}
          >
            <span className={"searchStyles"}>
              {device.serialNumber || "NA"}
            </span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{device.ipAddress || "-"}</Tooltip>}
          >
            <span className={"searchStyles"}>{device.ipAddress || "-"}</span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{device.port || "-"}</Tooltip>}
          >
            <span className={"searchStyles"}>{device.port || "-"}</span>
          </OverlayTrigger>
        </td>
        {selectedTabKey !== constants.SCAN_NETWORK_UNAUTHENTICATE_TAB && (
          <td>
            {selectedTabKey === constants.SCAN_NETWORK_ALREADY_ASSIGNED_TAB ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{device.areaName || "-"}</Tooltip>}
              >
                <span className={"searchStyles"}>{device.areaName || "-"}</span>
              </OverlayTrigger>
            ) : (
              <>
                <div className="custom-dropdown-container">
                  <select
                    className="custom-dropdown-options"
                    value={device.areaId}
                    onChange={(e) => onSelectLocationAreaHandler(e, device)}
                  >
                    {gatewayLocationAreas?.length > 0 ? (
                      gatewayLocationAreas?.map((area) => (
                        <option
                          key={`location-${area.areaId}`}
                          className={`area-selector-menu-item`}
                          value={area.areaId}
                        >
                          {area.areaName}
                        </option>
                      ))
                    ) : (
                      <option
                        key={`location-${0}`}
                        className={`area-selector-menu-item`}
                      >
                        {constants.LOCATION_DROPDOWN_NO_AREA_DATA_TEXT}
                      </option>
                    )}
                  </select>
                </div>
              </>
            )}
          </td>
        )}
      </tr>
    );
  };

  const displayGatewayDetails = () => {
    return (
      <>
        {filteredChannelsByTab?.length === 0 && showLoader ? (
          <tr>
            <td rowSpan={10} colSpan={7} className="loader-container">
              <SiteSpinner width="72px" height="72px" />
              <div className="loading-text">
                {constants.SCAN_NETWORK_SCANNING_LOADING_TEXT}
              </div>
            </td>
          </tr>
        ) : filteredChannelsByTab?.length === 0 ? (
          <tr>
            <td rowSpan={10} colSpan={7} className="no-device-container">
              <img alt="exclamation" src={CircleExclamation} />
              <div className="no-device-content mt-3">
                <div className="no-device-heading">
                  {constants.SCAN_NETWORK_NO_DEVICE_TITLE}
                </div>
                <div className="no-device-text">
                  {constants.SCAN_NETWORK_NO_DEVICE_HELPING_TEXT}
                </div>
              </div>
            </td>
          </tr>
        ) : (
          <>
            {filteredChannelsByTab?.map((device) => (
              <>{displayDeviceRowDetails(device)}</>
            ))}
          </>
        )}
      </>
    );
  };

  const displayHeaderDetails = (headerList) => {
    return (
      <>
        <tr>
          {headerList?.map((heading, index) => (
            <th>
              {heading.id === "checkbox" ? (
                <span className="delete-checkbox">
                  <input
                    type="checkbox"
                    className="delete-checkbox "
                    checked={
                      selectedChannels?.length !== 0 &&
                      selectedChannels.length === filteredChannelsByTab.length
                    }
                    disabled={
                      selectedTabKey ===
                        constants.SCAN_NETWORK_ALREADY_ASSIGNED_TAB &&
                      isNvrDeviceCountLimitReached()
                    }
                    onChange={(e) => onChangeCheckBox(e, "all")}
                    role="button"
                  />
                </span>
              ) : (
                <div
                  className={
                    headerList?.length - 1 === index
                      ? "without-border"
                      : "with-border"
                  }
                >
                  {heading?.displayName}
                  {heading?.id === "checkbox" ? null : pageDetails.orderBy ===
                      heading?.id && pageDetails.isAsc ? (
                    <IoIosArrowRoundDown
                      size={20}
                      color="#0000004A"
                      role="button"
                      onClick={() => onSort(heading)}
                    />
                  ) : pageDetails.orderBy === heading?.id &&
                    !pageDetails.isAsc ? (
                    <IoIosArrowRoundUp
                      size={20}
                      color="#0000004A"
                      role="button"
                      onClick={() => onSort(heading)}
                    />
                  ) : (
                    <RxCaretSort
                      size={20}
                      color="#0000004A"
                      role="button"
                      onClick={() => onSort(heading)}
                    />
                  )}
                </div>
              )}
            </th>
          ))}
          {filteredChannelsByTab?.length === 0 &&
          selectedTabKey === constants.SCAN_NETWORK_UNAUTHENTICATE_TAB ? (
            <tr></tr>
          ) : null}
        </tr>
      </>
    );
  };

  const getTotalAddedDeviceCountMsg = () => {
    const { deviceCount = "-", totalCapacity = "-" } = deviceCountData || {};
    const { deviceType } = gatewayDeviceData || {};

    if (deviceCount <= 0 || deviceCount === "-") {
      return null;
    }

    if (deviceType?.toUpperCase() !== DeviceTypeEnum.NVR) {
      const deviceLabel =
        deviceCount === 1
          ? constants.SCAN_NETWORK_DEVICE_LABEL
          : constants.SCAN_NETWORK_DEVICES_LABEL.replace(
              "{deviceCount}",
              deviceCount
            );
      return `${deviceLabel} ${constants.SCAN_NETWORK_DEVICES_ADDED_MSG}`;
    }

    if (selectedTabKey === constants.SCAN_NETWORK_UNAUTHENTICATE_TAB) {
      return constants.SCAN_NETWORK_DEVICES_ADDED_DEVICE_COUNT_TEXT.replace(
        "{deviceCount}",
        deviceCount
      ).replace("{totalCapacity}", totalCapacity);
    }

    if (deviceCount && deviceCount !== "-" && deviceCount >= totalCapacity) {
      return (
        <div className="limit-reached-text">
          {constants.SCAN_NETWORK_DEVICES_LIMIT_REACHED_MSG.replace(
            "{deviceCount}",
            totalCapacity
          )}
        </div>
      );
    }

    return constants.SCAN_NETWORK_DEVICES_ADDED_DEVICE_COUNT_TEXT.replace(
      "{deviceCount}",
      deviceCount
    ).replace("{totalCapacity}", totalCapacity);
  };

  const isNvrDeviceCountLimitReached = () => {
    const { deviceCount, totalCapacity } = deviceCountData || {};
    return (
      gatewayDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR &&
      deviceCount &&
      deviceCount !== "-" &&
      deviceCount === totalCapacity
    );
  };

  return (
    <div
      className={`scan-network-wrapper ${showLoader ? "loader-overlay" : ""}`}
    >
      <div className="gateway-device-info">
        <div className="gateway-device-icon">
          <img
            alt="gateway"
            className={``}
            onError={(e) => (e.target.src = `${DefaultDevice}`)}
            src={gatewayDeviceData?.imageURL}
          />
          <div className="small-online-indicator"></div>
        </div>
        <div className="gateway-device-title">
          {gatewayDeviceData?.deviceName} :
        </div>
        <div className="added-device-count-info">
          {getTotalAddedDeviceCountMsg()}
        </div>
      </div>
      <div className="pb-3 tabs-container mt-1">
        <Tabs
          defaultActiveKey={tabList[0]}
          className="tabs-list"
          activeKey={selectedTabKey}
          onSelect={(e) => {
            handleTabChange(e);
          }}
        >
          {tabList.map((tab) => (
            <Tab key={tab} eventKey={tab} title={tab} />
          ))}
        </Tabs>
      </div>
      <div className="header-btn-container">
        <div className="left-btn-container">
          {selectedTabKey === constants.SCAN_NETWORK_UNAUTHENTICATE_TAB ? (
            <div
              className={`primary-btn ${
                selectedChannelCount === 0 || showLoader ? "disabled" : ""
              }`}
              role="button"
              onClick={() => handleAuthenticateBtnClick()}
            >
              {constants.SCAN_NETWORK_AUTHENTICATE_BTN_LABEL}
            </div>
          ) : selectedTabKey === constants.SCAN_NETWORK_ALREADY_ASSIGNED_TAB ? (
            <div
              className={`primary-btn ${
                selectedChannelCount === 0 ||
                showLoader ||
                isNvrDeviceCountLimitReached()
                  ? "disabled"
                  : ""
              }`}
              role="button"
              onClick={() => handleMoveBtnClick()}
            >
              {constants.SCAN_NETWORK_MOVE_BTN_LABEL}
            </div>
          ) : (
            <>
              <div
                className={`primary-btn ${
                  selectedChannelCount === 0 ||
                  showLoader ||
                  isNvrDeviceCountLimitReached()
                    ? "disabled"
                    : ""
                }`}
                role="button"
                onClick={() => handleAddBtnClick()}
              >
                <HiOutlinePlus size={20} />
                {constants.SCAN_NETWORK_ADD_BTN_LABEL}
              </div>
              <div
                className={`primary-btn ${
                  selectedChannelCount === 0 || showLoader ? "disabled" : ""
                }`}
                role="button"
                onClick={() => handleSetAreaBtnClick()}
              >
                <TbMapPin size={18} />
                {constants.SCAN_NETWORK_SET_AREA_BTN_LABEL}
              </div>
            </>
          )}
          <div className="selected-item-label">
            {selectedChannelCount !== 0 && (
              <>
                <span className="item-count">{selectedChannelCount}</span>
                {constants.SCAN_NETWORK_SELECTED_ITEM_LABEL}
              </>
            )}
            {passedAuthDevices.length > 0 && (
              <span className="success-text">
                {passedAuthDevices.length === 1
                  ? `${constants.SCAN_NETWORK_DEVICE_LABEL} `
                  : `${constants.SCAN_NETWORK_DEVICES_LABEL.replace(
                      "{deviceCount}",
                      passedAuthDevices.length
                    )} `}
                {selectedTabKey === constants.SCAN_NETWORK_UNAUTHENTICATE_TAB
                  ? constants.SCAN_NETWORK_SUCCESS_ITEM_LABEL
                  : selectedTabKey ===
                    constants.SCAN_NETWORK_ALREADY_ASSIGNED_TAB
                  ? constants.SCAN_NETWORK_SUCCESS_MOVEED_ITEM_LABEL
                  : constants.SCAN_NETWORK_SUCCESS_ADDED_ITEM_LABEL}
              </span>
            )}
            {failedAuthDevices.length > 0 && (
              <span className="normal-text">
                {passedAuthDevices.length > 0 && "["}
                {failedAuthDevices.length === 1
                  ? `${constants.SCAN_NETWORK_DEVICE_LABEL} `
                  : `${constants.SCAN_NETWORK_DEVICES_LABEL.replace(
                      "{deviceCount}",
                      failedAuthDevices.length
                    )} `}
                {constants.SCAN_NETWORK_FAILED_ITEM_LABEL}
                {passedAuthDevices.length > 0 && "]"}
              </span>
            )}
            {errorMsg && <span className="normal-text">{errorMsg}</span>}
          </div>
        </div>
        <div className="right-btn-container">
          <div
            className="primary-btn"
            role="button"
            onClick={() => handleReloadBtnClick()}
          >
            <TfiReload size={16} />
            {constants.SCAN_NETWORK_RELOAD_BTN_LABEL}
          </div>
        </div>
      </div>
      {filteredChannelsByTab?.length !== 0 && showLoader && (
        <div className="position-absolute loader-container top-50 left-50">
          <SiteSpinner width="72px" height="72px" />
          <div className="loading-text">{constants.LOADING}</div>
        </div>
      )}
      <div className="scan-devices-container">
        {selectedTabKey === constants.SCAN_NETWORK_UNAUTHENTICATE_TAB ? (
          <ResizableTable
            widths={[27, 365, 260, 320, 275, 275]}
            minWidths={[27, 365, 260, 320, 234, 234]}
            minWidth={27}
            disabledColumns={[0]}
            displayHeaderDetails={() =>
              displayHeaderDetails(paginatedHeaderList)
            }
            displayGatewayDetails={() => displayGatewayDetails()}
          />
        ) : (
          <ScanNetworkModalWithArea
            displayHeaderDetails={displayHeaderDetails}
            displayGatewayDetails={displayGatewayDetails}
          />
        )}
      </div>
      {showAuthenticateModal && (
        <AuthenticateModal
          channelsToBeAuthenticated={selectedChannels}
          hubId={hubId}
          onHideModal={hideAuthenticateModalHandler}
          onOkBtnClick={okAuthBtnClickHandler}
        />
      )}
      {showSetAreaModal && (
        <SetAreaModal
          onHideModal={hideSetAreaModalHandler}
          onOkBtnClick={okSetAreaBtnClickHandler}
          gatewayLocationAreas={gatewayLocationAreas}
          defaultGatewayArea={defaultGatewayAreaRef.current}
        />
      )}
      {showMoveModal && (
        <MoveModal
          channelsToBeMoved={selectedChannels}
          gatewayDeviceData={gatewayDeviceData}
          onHideModal={hideMoveModalHandler}
          onOkBtnClick={okMoveBtnClickHandler}
          scannedDevicesTree={scannedDevicesTree}
          defaultGatewayArea={defaultGatewayAreaRef.current}
        />
      )}
    </div>
  );
};

export default ScanNetworkModal;
