const colors = [
  // Brand Main Colors
  "brand_primary",
  "brand_black",
  "brand_white",
  // Primary Colors
  "primary_08",
  "primary_16",
  "primary_24",
  "primary_32",
  "primary_40",
  "primary_48",
  "primary_56",
  "primary_64",
  "primary_72",
  "primary_80",
  "primary_88",
  "primary_96",
  // Neutrals-Hanwha
  "hGreyscale_08",
  "hGrayscale_16",
  "hGrayscale_24",
  "hGrayscale_32",
  "hGreyscale_40",
  "hGreyscale_48",
  "hGreyscale_56",
  "hGreyscale_64",
  "hGreyscale_72",
  "hGreyscale_80",
  "hGreyscale_88",
  "hGrayscale_96",
  // Neutrals
  "greyscale_08",
  "grayscale_16",
  "grayscale_24",
  "grayscale_32",
  "greyscale_40",
  "greyscale_48",
  "greyscale_56",
  "greyscale_64",
  "greyscale_72",
  "greyscale_80",
  "greyscale_88",
  "grayscale_96",
  // Text
  "text_color",
  "body_color",
  "text_disable_color",
  // Feedback - Success
  "success_24",
  "success_48",
  "success_64",
  "success_80",
  "success_96",
  // Feedback - Warning
  "warning_24",
  "warning_48",
  "warning_64",
  "warning_80",
  "warning_96",
  // Feedback - Error
  "error_24",
  "error_48",
  "error_64",
  "error_80",
  "error_96",
  "error_112",
  // Overlay
  "overlay",
  // Avatar
  "avatar_dark_grey",
  "avatar_dark_red",
  "avatar_dark_orange",
  "avatar_dark_yellow",
  "avatar_dark_lime",
  "avatar_dark_teal",
  "avatar_dark_turquoise",
  "avatar_dark_aqua",
  "avatar_dark_blue",
  "avatar_dark_brand",
  "avatar_dark_ultramarine",
  "avatar_dark_purple",
  "avatar_dark_pink",
  "avatar_light_grey",
  "avatar_light_red",
  "avatar_light_orange",
  "avatar_light_yellow",
  "avatar_light_lime",
  "avatar_light_teal",
  "avatar_light_turquoise",
  "avatar_light_aqua",
  "avatar_light_blue",
  "avatar_light_brand",
  "avatar_light_ultramarine",
  "avatar_light_purple",
  "avatar_light_pink",
  // Other
  "list_block_background_color",
  "error_block_background_color",
  "warning_block_label_value_color",
  "axis_border_timeline",
  "transparent_color",
  "diff_positive",
  "diff_text_positive",
  "all_result",
  "tagvlm",
  "tag_vlm_bg",
  "tag_orange",
  "tag_red",
  "tag_blue",
  "tag_purple",
  "progress_org_device",
  "seprator_device",
  "error_block_value_color",
  "bs-breadcrumb-divider-color",
  "breadcrumb-color1",
  "button-color-1",
  "device_error_trail_color",
  "custom_color_1",
  "custom_color_2",
  "custom_color_3",
  "custom_color_4",
  "custom_color_5",
  "custom_color_6",
  "custom_color_7",
  "custom_color_8",
  "custom_color_9",
  "custom_color_10",
  "custom_color_11",
  "custom_color_12",
  "custom_color_13",
  "custom_color_14",
  "custom_color_15",

  // RGB Colors
  "greyscale-custom1",
  "greyscale-custom2",
  "greyscale-custom3",
  "error80-custom1",
  "error64-custom1",
  // shockwave colors
  "light_orange",
  "dark_orange",
  "custom_color_16",
  "custom_color_17",
  "custom_color_18",
  "custom_color_19",
  "custom_color_20",
  "custom_color_21",
  "custom_color_22",
  "custom_color_23",
  "custom_color_24",
  "custom_color_25",
  "custom_color_26",
  "custom_color_27",
  "custom_color_28",
  "greyscale_00",
  "custom_color_29",
  "custom_color_30",
  "custom_color_31",
  "custom_color_32",
  "custom_color_33",
  "custom_color_38",
];
export default { colors };
