import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { constants, Utils } from "../../helpers";
import { useCustomerOrgLocations } from "../../store/CustomerOrgLocationsStore";
import LocationsMap from "./LocationsMap";
import { Container, Col, Row, NavDropdown, Nav } from "react-bootstrap";
import {
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteModal,
  SiteToast,
} from "../../components/common";
import PageWrapper from "../PageWrapper";
import { EditLocation, NewLocation } from ".";
import {
  getFilteredDeviceData,
  setFilteredDevicesData,
} from "../../store/reducers/AccountReducer";
import { usePoliciesStore } from "../../store/policiesStore";
import { useSelector, useDispatch } from "react-redux";
import { RxPlus } from "react-icons/rx";
import "./ManageLocations.scss";
import arrowLeft from "../../assets/images/icons/Arrowleft.svg";
import dotshorizontal from "../../assets/images/icons/Dotshorizontal.svg";
import { getCustomerOrgData } from "../../store/OrganizationsStoreIDB";
import {
  getCustomerOrgDevices,
  setCustomerOrgDevices,
} from "../../store/CustomerOrgDevicesStoreIDB";
import { setAllDevicesData } from "../../store/AccountStoreIDB";
import { setDevicesListOfCurrOrg } from "../../store/NVRDeviceStoreIDB";
import axios from "axios";

function ManageLocations() {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");
  const [locationsData, setLocationsData] = useState([]);
  const [showAddLocationsModal, setShowAddLocationsModal] = useState(false);
  const [userMsg, setUserMsg] = useState("");
  const deviceListData = useSelector(getFilteredDeviceData);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState(false);
  const [editLocationsId, setEditLocationsId] = useState(null);
  const [editLocationsData, setEditLocationsData] = useState([]);
  // const custOrgData = useSelector(getSelectedOrganization);
  const [custOrgData, setCustOrgData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //=== Store get/set actions
  const getCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocations
  );
  const customerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.customerOrgLocationsData
  );
  const getCustomerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocationsData
  );
  const getCustomerOrgEditLocationsData = useCustomerOrgLocations(
    (state) => state.getCustomerOrgEditLocationsData
  );
  // const customerOrgData = useOrganizations((state) => state.customerOrgData);
  // const custOrgData = customerOrgData?.find((user) => user.orgId === orgId);
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );

  const breadList = [
    // { url: '/customers/manage.html', title: constants.MANAGE_ORG_PAGE_TITLE },
    {
      url: Utils.CheckLoggedInUserRole(custOrgData?.orgId, custOrgData?.orgName)
        ?.url,
      title: Utils.CheckLoggedInUserRole(custOrgData, custOrgData?.orgName)
        ?.title,
    },
    {
      url: `/landing-page.html?orgId=${orgId}&orgName=${custOrgData?.orgName}&fromPush=true`,
      title: custOrgData?.orgName,
    },
    {
      url: `/locations/manage.html?orgId=${orgId}`,
      title: constants.LOCATIONS_PAGE_TITLE,
    },
  ];
  const mobileBreadList = [
    {
      url: `/landing-page.html?orgId=${orgId}&orgName=${custOrgData?.orgName}&fromPush=true`,
    },
  ];

  useEffect(() => {
    const getOrg = async () => {
      const orgs = await getCustomerOrgData();
      const orgData = orgs?.find((user) => user.orgId === orgId);
      setCustOrgData(orgData);
    };
    getOrg();
  }, [orgId]);

  const fetchDevices = async () => {
    await axios
      .get(`device/orgs/${orgId}/devices`, Utils.requestHeader())
      .then(async (response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;

          const updatedResponseData =
            responseData &&
            responseData?.map((item) => {
              if (
                !(
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO in
                  item?.apps
                )
              ) {
                item.apps[
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO
                ] = false;
              }
              if (
                !(
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI in
                  item?.apps
                )
              ) {
                item.apps[
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI
                ] = false;
              }
              if (
                !(
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND in
                  item?.apps
                )
              ) {
                item.apps[
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND
                ] = false;
              }
              if (
                !(constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS in item?.apps)
              ) {
                item.apps[
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
                ] = false;
              }
              return item;
            });
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === "200")
          ) {
            await setAllDevicesData(
              JSON.parse(JSON.stringify(updatedResponseData))
            );
            const groupedDeviceData =
              Utils.getGroupedDevices(updatedResponseData);
            dispatch(setFilteredDevicesData(groupedDeviceData));
            const updatedData = updatedResponseData.filter(
              (device) => device.deviceStatus !== "DEACTIVATED"
            );
            await setDevicesListOfCurrOrg(updatedData);
            await setCustomerOrgDevices(updatedResponseData);
            fetchLocations();
          }
        } else {
          fetchLocations();
        }
      });
  };

  const fetchLocations = async () => {
    try {
      await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
      if (getCustomerOrgLocationsData()?.length > 0) {
        const devicesList = await getCustomerOrgDevices();
        let locations = await getCustomerOrgLocationsData()?.map((location) => {
          const dataDevice = Utils.getTotalChildDevices(devicesList);
          const deviceExists = dataDevice?.filter(
            (device) => device.locationId === location.locationId
          );
          return {
            lat: location.lat,
            lng: location?.lng,
            deviceCount: deviceExists?.length,
            locationId: location?.locationId,
            locationName: location?.locationName,
            address: location?.addressLine1,
            orgId: location?.orgId,
            orgName: custOrgData?.orgName,
          };
        });
        await setLocationsData(locations);
      }
    } catch (error) {
      setUserMsg(error);
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchDevices();
    }
  }, [orgId]);

  const handleEditLocations = async (locationId) => {
    if (orgId && locationId) {
      await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
      const ediLocData = await getCustomerOrgEditLocationsData(locationId);
      setEditLocationsData(ediLocData);
      setEditLocationsId(locationId);
      setShowEditLocationsModal(true);
    }
  };

  return (
    <div className="App manage-locations-page">
      <Header
        showHeader={true}
        breadcrumbData={breadList}
        className="desktop"
      />
      <div className="breadcrumbsResponsive mobile">
        <div className="text-start me-auto navbar-brand arrow">
          <img src={arrowLeft} alt=""></img>
          <spam
            className="backText"
            onClick={() => navigate(mobileBreadList[0]?.url)}
          >
            {constants.BREADCRUMB_BACK}
          </spam>
        </div>
        <Nav>
          <NavDropdown
            className="mobile"
            title={
              <div>
                <img className="dots" src={dotshorizontal} alt=""></img>
              </div>
            }
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item
              href="#"
              onClick={() => {
                setShowAddLocationsModal(true);
              }}
            >
              <div>{constants.NEW_LOCATIONS_BUTTON_TITLE}</div>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </div>
      <PageWrapper className="mw-100">
        <div className="page-header">
          <Container className="mw-100 w-100">
            <Row>
              <Col className="text-start">
                <BreadcrumbList
                  list={Utils.CheckBreadcrumbForUserRole(breadList)}
                />
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="page-title text-start"
              >
                {constants.LOCATIONS_PAGE_TITLE}
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="end-align page-header-buttons"
              >
                {getCustomerOrgPolicies().create_location && (
                  <PrimaryButton
                    className="d-md-block d-none btn-primary"
                    type="button"
                    width="auto"
                    height="44px"
                    fontSize="14px"
                    onClick={() => {
                      setShowAddLocationsModal(true);
                    }}
                  >
                    <RxPlus
                      className="plus-icon-btn"
                      strokeWidth={"1.5px"}
                      size={13.33}
                    />
                    {constants.NEW_LOCATIONS_BUTTON_TITLE}
                  </PrimaryButton>
                )}
              </Col>
            </Row>
          </Container>
        </div>

        {/* Show error messages */}
        <SiteToast title="Error" show={!!userMsg} body={userMsg} />

        {/* New partner user - modal */}
        <SiteModal
          modalTitle={constants.NEW_LOCATIONS_PAGE_TITLE}
          showModal={showAddLocationsModal}
          hideModal={() => setShowAddLocationsModal(false)}
          classes="manage-location-main"
        >
          <NewLocation
            reloadData={() => {
              fetchLocations();
            }}
            hideModal={() => setShowAddLocationsModal(false)}
          />
        </SiteModal>

        {/* Edit partner user - modal */}
        <SiteModal
          modalTitle={constants.EDIT_LOCATIONS_PAGE_TITLE}
          showModal={showEditLocationsModal}
          hideModal={() => setShowEditLocationsModal(false)}
          classes="manage-location-main"
        >
          <EditLocation
            locationId={editLocationsId}
            locationsData={editLocationsData}
            reloadData={() => {
              fetchLocations();
            }}
            hideModal={() => setShowEditLocationsModal(false)}
          />
        </SiteModal>
      </PageWrapper>
      {/* Google Map with marker cluster */}
      {locationsData?.length > 0 && getCustomerOrgPolicies().view_location && (
        <LocationsMap
          locationPositions={locationsData}
          handleEditLocation={(locationId) => handleEditLocations(locationId)}
        />
      )}
    </div>
  );
}

export default ManageLocations;
