import { constants } from "../../../helpers";
import ResizableTable from "./ResizableTable";

const EditDevices = ({ displayHeaderDetails, displayGatewayDetails }) => {
  const paginatedHeaderList = [
    {
      displayName: "",
      id: "arrow",
    },
    {
      displayName: "",
      id: "checkbox",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_NAME,
      id: "deviceName",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_MODEL,
      id: "modelNumber",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_SN,
      id: "serialNo",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_STATUS,
      id: "status",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_LOCATION,
      id: "location",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_AREA,
      id: "area",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_APPLICATIONS_LINKED,
      id: "applications-linked",
    },
  ];

  return (
    <>
      <ResizableTable
        widths={[29, 26, 366, 200, 296, 120, 140, 140, 240]}
        minWidths={[29, 26, 316, 160, 160, 100, 100, 100, 240]}
        minWidth={26}
        disabledColumns={[0, 1, 7]}
        displayHeaderDetails={() => displayHeaderDetails(paginatedHeaderList)}
        displayGatewayDetails={() => displayGatewayDetails()}
      ></ResizableTable>
    </>
  );
};

export default EditDevices;
