import { Dropdown } from "react-bootstrap";
import { useEffect, useState, useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Header,
  BreadcrumbList,
  PrimaryButton,
  SiteModal,
} from "../../../components/common";
import { constants, Utils, DeviceTypeEnum } from "../../../helpers";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import {
  setDeviceInformation,
  setLocationsData,
  getAllMqttDataFromResponse,
  setAllMqttDataFromResponse,
  setDevicesSelectedLocation,
  getDevicesSelectedLocation,
} from "../../../store/reducers/AccountReducer";
import "../../../assets/css/loader.scss";
import {
  getMQTTConnection,
  getPlatformInfo,
  setLiveStream,
  setRemoteStream,
} from "../../../store/reducers/StreamingReducer";
import { usePoliciesStore } from "../../../store/policiesStore";
import { disconnectWithMQTT } from "../../../utils/connection/mqttConnection";
import useLicensesStore from "../../../store/LicensesStore";
import { IoIosArrowRoundUp, IoIosSearch } from "react-icons/io";
import { HiOutlineChevronDown, HiOutlineChevronRight } from "react-icons/hi";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { HiOutlineChevronLeft } from "react-icons/hi2";
import moment from "moment";
import SiteSpinner from "../../../components/common/SiteSpinner";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  setNVRDeviceData,
  setManufacturerSKUList,
} from "../../../store/reducers/NVRDeviceReducer";
import { useAppsStore } from "../../../store/AppsStore";
import { getCustomerOrgData } from "../../../store/OrganizationsStoreIDB";
import { getSelectedOrganization } from "../../../store/AccountStoreIDB";
import {
  setAllScannedDevices,
  setNVRScannedDevices,
} from "../../../store/NVRDeviceStoreIDB";
import { observerInstance } from "../../../store/indexDB/observer";
import useDebouncedCallback from "../../../hooks/useDebouncedCallback";
import { getOrgInfo } from "../../../store/reducers/OrganizationsReducer";
import "./DiscoveredDevices.scss";
import { IoIosArrowRoundDown } from "react-icons/io";
import { RxCaretSort } from "react-icons/rx";
import { TfiReload } from "react-icons/tfi";
import { CiFilter } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { GoPencil } from "react-icons/go";
import { BsPlus } from "react-icons/bs";
import { PiTrash } from "react-icons/pi";
import { IoCloseCircle } from "react-icons/io5";

import { ReactComponent as StepBottomIcon } from "../../../assets/images/step_bottom.svg";
import { CircleExclamation, DefaultDevice } from "../../../assets/images";

import ResizableTable from "./ResizableTable";
import DevicesFilters from "./DevicesFilters";
import LocationAreaSelectorModal from "./LocationAreaSelectorModal";
import RemoveDeviceModal from "./RemoveDeviceModal";
import {
  channelsAPI,
  gatewaysAPI,
  getDeviceTypesAPI,
  removeChannelAPI,
  renameChannelAPI,
  updateDeviceAreaAPI,
  updateDeviceLoctaionAreaAPI,
} from "./DevicesAPI";
import { sassTrue } from "sass";
import EditDevices from "./EditDevices";
import ScanNetworkModal from "./ScanNetworkModal";
import highlightWords from "highlight-words";
import { TbMapPin } from "react-icons/tb";

const Devices = () => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");
  const locationId = searchParams.get("locationId");
  const orgInfo = useSelector(getOrgInfo);
  const [searchText, setSearchText] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [licensesData, setLicensesData] = useState([]);
  const [filteredDeviceData, setFilteredDeviceData] = useState([]);
  const mqttConnection = useSelector(getMQTTConnection);
  const [orgDetails, setOrgDetails] = useState();
  const [locationDetails, setLocationDetails] = useState(null);
  const platformDetails = useSelector(getPlatformInfo);
  const { getLicenses, setLicenses, getExpiredLicensesInStore } =
    useLicensesStore();
  const navigate = useNavigate();
  const [showScanNetworkModal, setShowScanNetworkModal] = useState(false);
  const [gatewayDeviceData, setGatewayDeviceData] = useState(null);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showLoaderMore, setShowLoaderMore] = useState(false);
  const [discovredDevciesCount, setDiscovredDevciesCount] = useState(0);
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const [isDiscoverPopupLoading, setIsDiscoverPopupLoading] = useState(null);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const [expandedParent, setExpandedParent] = useState(null);
  const [isDisplayFiltersModal, setIsDisplayFiltersModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [renamedDeviceName, setRenamedDeviceName] = useState(null);
  const [renamingDevice, setRenamingDevice] = useState(null);
  const [isRenamingDevice, setIsRenamingDevice] = useState(false);
  const [isRemovingDevice, setIsRemovingDevice] = useState(false);
  const [isDisplayLocationAreaModal, setIsDisplayLocationAreaModal] =
    useState(false);
  const [isDisplayRemoveDeviceModal, setIsDisplayRemoveDeviceModal] =
    useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedDeviceToRemove, setSelectedDeviceToRemove] = useState(null);
  const [totalDevices, setTotalDevices] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isCheckForSearchCSS, setIsCheckForSearchCSS] = useState(false);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [isD2CInSelectedDevices, setIsD2CInSelectedDevices] = useState(false);
  const [selectEligibleDevices, setSelectEligibleDevices] = useState([]);
  const [updateUserMessage, setUpdateUserMessage] = useState(null);
  const renameInputRef = useRef();

  const defaultPageDetails = {
    orderBy: "deviceName",
    isAsc: true,
    page: 0,
    size: 100,
  };
  const newChildPageDetails = {
    orderBy: "deviceName",
    isAsc: true,
    page: -1,
    size: 8,
  };
  const defaultFilters = {
    connectType: [],
    connectionStatus: [],
    deviceStatus: [],
    firmwareUpdate: [],
    applicationsLinked: [],
  };
  const [pageDetails, setPageDetails] = useState(defaultPageDetails);
  const [filtersData, setFiltersData] = useState(defaultFilters);

  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;
  const devicesSelectedLocation = useSelector(getDevicesSelectedLocation);
  let [selectedLocation, setSelectedLocation] = useState(null);
  const getApps = useAppsStore((state) => state.getApps);
  let appList = useAppsStore((state) => state.appList);
  appList = appList?.filter(
    (item) => item?.name !== constants.FLEXAI_PROD_LABEL
  );
  const dispatch = useDispatch();
  let [customerOrgPolicies, setCustomerOrgPolicies] = useState([]);

  const breadList = [
    {
      url: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)?.url,
      title: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)
        ?.title,
    },
    {
      url: `/landing-page.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}&fromPush=true`,
      title: `${orgInfo?.orgName ? orgInfo?.orgName : ""}`,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
  ];

  const editModeBreadList = [
    {
      url: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)?.url,
      title: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)
        ?.title,
    },
    {
      url: `/landing-page.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}&fromPush=true`,
      title: `${orgInfo?.orgName ? orgInfo?.orgName : ""}`,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: "Edit",
    },
  ];

  const paginatedHeaderList = [
    {
      displayName: "",
      id: "arrow",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_NAME,
      id: "deviceName",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_MODEL,
      id: "modelNumber",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_SN,
      id: "serialNo",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_STATUS,
      id: "status",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_LOCATION,
      id: "location",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_AREA,
      id: "area",
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_APPLICATIONS_LINKED,
      id: "applications-linked",
    },
  ];

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustomerOrgPolicies(orgs?.[0]?.policies || []);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === "selectedOrganization") {
        await debouncedLoadSelectedOrgData();
      }

      if (data.key === "customerOrgData") {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData, debouncedLoadCustomerOrgData]);

  useEffect(() => {
    localStorage.setItem("modalOpen", 0);
    fetchManufacturerData();
    fetchLicensesData();
    getLocations();
    fetchDiscoveredDevicesCount();
    fetchDeviceTypes();
    dispatch(setLiveStream(null));
    dispatch(setRemoteStream(null));
    dispatch(setDeviceInformation(null));
    if (mqttConnection) {
      disconnectWithMQTT();
    }
  }, []);

  useEffect(() => {
    const resetNVRScanData = async () => {
      await setNVRScannedDevices([]);
      await setAllScannedDevices([]);
    };
    resetNVRScanData();
  }, []);

  useEffect(() => {
    if (locationDetails?.length && locationDetails?.[0]?.orgId === orgId) {
      fetchGatewayData(searchText);
    }
  }, [JSON.stringify(pageDetails), JSON.stringify(filtersData)]);

  useEffect(() => {
    getApps(`partner/apps`);
  }, []);

  useEffect(() => {
    if (expandedParent?.deviceId) {
      fetchChannelDataByParentId();
    }
  }, [expandedParent?.deviceId]);

  useEffect(() => {
    if (selectedLocation?.locationId) {
      onClickReset();
    }
  }, [selectedLocation?.locationId]);

  const outsideClickRenameBox = (event) => {
    const editContainer = document.getElementsByClassName(
      "edit-name-container"
    )?.[0];
    if (
      !editContainer?.contains(event?.target) &&
      renameInputRef?.current?.value
    ) {
      window?.removeEventListener("click", outsideClickRenameBox);
      onRenameDevice(renameInputRef?.current?.value);
    }
  };

  useEffect(() => {
    if (renamingDevice?.deviceId) {
      window?.addEventListener("click", outsideClickRenameBox);
    } else {
      window?.removeEventListener("click", outsideClickRenameBox);
    }

    return () => {
      window?.removeEventListener("click", outsideClickRenameBox);
    };
  }, [renamingDevice?.deviceId]);

  const findParentNAttachChilds = (parentList, children, childPageDetails) => {
    parentList.forEach((parent) => {
      if (parent.deviceId === expandedParent?.deviceId) {
        children?.forEach((child) => {
          child = addDeviceProperties(child, parent?.stepCount + 1);
        });
        parent.childDevices = childPageDetails?.page
          ? [...parent.childDevices, ...children]
          : children;
        parent.childPageDetails = childPageDetails;
      } else if (parent?.childDevices?.length) {
        parent = findParentNAttachChilds(
          parent?.childDevices,
          children,
          childPageDetails
        );
      }
    });
    return parentList;
  };

  const addDeviceProperties = (device, stepCount) => {
    const avlbleDeviceVersion = device?.properties?.["avlbl-device-ver"];
    const firmwareVersion = device?.properties?.["firmware-version"];
    const checkStatus =
      avlbleDeviceVersion && firmwareVersion
        ? avlbleDeviceVersion?.toLowerCase() !== firmwareVersion?.toLowerCase()
        : false;

    device["displayDeviceStatus"] = Utils.getDeviceStatus(
      device.deviceStatus,
      device.connectionStatus
    );
    if (getExpiredLicensesInStore()?.length > 0) {
      device["isLicenseExpired"] = getExpiredLicensesInStore()
        ?.map((item) => item.deviceId)
        ?.includes(device?.deviceId)
        ? true
        : false;
    }
    device["isFirmwareUpgradeAvailable"] =
      device?.properties && device?.properties?.["avlbl-duclo-ver"]
        ? device?.properties?.["avlbl-duclo-ver"] !==
          device?.properties?.["edge-app-version"]
        : avlbleDeviceVersion
        ? avlbleDeviceVersion?.toLowerCase() !== firmwareVersion?.toLowerCase()
        : false;
    if (!device["isFirmwareUpgradeAvailable"]) {
      device["isFirmwareUpgradeAvailable"] = checkStatus;
    }

    device["displayLicensesStatus"] = getLicensesStatus(device);
    device["displayArea"] = locationDetails?.map((location) => {
      if (location.locationId === device.locationId) {
        return location?.areas?.map((area) => {
          if (area.areaId === device.areaId) {
            return area.areaName;
          }
        });
      }
    });
    device["displayLocation"] = locationDetails?.map((location) => {
      if (location.locationId === device.locationId) {
        return location.locationName;
      }
    });
    device["stepCount"] = stepCount;

    if (
      device?.apps &&
      !(constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO in device?.apps)
    ) {
      device.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO] = false;
    }
    if (
      device?.apps &&
      !(constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI in device?.apps)
    ) {
      device.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI] = false;
    }
    if (
      device?.apps &&
      !(constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND in device?.apps)
    ) {
      device.apps[
        constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND
      ] = false;
    }
    if (
      device?.apps &&
      !(constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS in device?.apps)
    ) {
      device.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS] = false;
    }
    if (!device?.count) {
      device.count = 0;
    }
    return device;
  };

  const fetchGatewayData = async (search) => {
    setShowLoader(true);
    setSearchText(search?.trim());
    let gatewayAPIData = await gatewaysAPI(
      orgId,
      selectedLocation?.locationId,
      pageDetails,
      search?.trim(),
      filtersData,
      deviceTypes
    );
    if (!gatewayAPIData?.userMsg) {
      gatewayAPIData.devices = gatewayAPIData?.pageNumber
        ? [...filteredDeviceData, ...gatewayAPIData.devices]
        : gatewayAPIData.devices;
      gatewayAPIData.devices?.forEach((device) => {
        device = addDeviceProperties(device, 0);
      });
      setFilteredDeviceData(gatewayAPIData?.devices);
      setTotalDevices(gatewayAPIData?.totalElements);
      setTotalPages(gatewayAPIData?.totalPages);
      setShowLoader(false);
      setShowLoaderMore(false);
      setIsCheckForSearchCSS(!isCheckForSearchCSS);
      setSearchedText(search?.trim());
    } else {
      setShowLoader(false);
      setShowLoaderMore(false);
    }
  };

  const fetchChannelDataByParentId = async () => {
    const channelAPIData = await channelsAPI(
      orgId,
      expandedParent,
      selectedLocation.locationId,
      pageDetails,
      searchText,
      filtersData,
      deviceTypes
    );
    if (!channelAPIData?.userMsg) {
      const parentList = findParentNAttachChilds(
        filteredDeviceData,
        channelAPIData?.devices,
        {
          page: channelAPIData?.pageNumber,
          totalPages: channelAPIData?.totalPages,
        }
      );
      setFilteredDeviceData(parentList);
      setExpandedParent(null);
      setIsCheckForSearchCSS(!isCheckForSearchCSS);
      setSearchedText(searchText);
    } else {
      setShowLoader(false);
      setShowLoaderMore(false);
      setExpandedParent(null);
    }
  };

  const fetchDiscoveredDevicesCount = async () => {
    await axios
      .get(
        `/device/orgs/${orgId}/devices/discover/count`,
        Utils.requestHeader()
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === "200")
          ) {
            setDiscovredDevciesCount(responseData.count);
          }
        }
      });
  };

  const fetchDeviceTypes = async () => {
    const deviceTypesData = await getDeviceTypesAPI(orgId);
    if (deviceTypesData?.deviceTypes) {
      const deviceTypes = deviceTypesData?.deviceTypes.map(
        (deviceType) => deviceType?.id
      );
      setDeviceTypes(deviceTypes);
    }
  };

  const getLocations = async () => {
    await axios
      .get(`partner/orgs/${orgId}/locations`, Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === "200")
          ) {
            const data = [...responseData];
            const defaultLocation = data?.filter(
              (location) => location.isDefault === "true"
            );
            const finalData = data.sort(function (x, y) {
              return x === defaultLocation[0]
                ? -1
                : y === defaultLocation[0]
                ? 1
                : 0;
            });
            if (locationId) {
              const locations = data?.filter(
                (location) => location.locationId === locationId
              );
              if (locations?.[0]) {
                setSelectedLocation(locations[0]);
                dispatch(setDevicesSelectedLocation(locations[0]));
              }
            } else if (
              devicesSelectedLocation &&
              devicesSelectedLocation?.orgId === orgId
            ) {
              setSelectedLocation(devicesSelectedLocation);
            } else {
              setSelectedLocation(defaultLocation[0]);
              dispatch(setDevicesSelectedLocation(defaultLocation[0]));
            }
            setLocationDetails(finalData);
            dispatch(setLocationsData(finalData));
          }
        }
      });
  };

  const fetchManufacturerData = async () => {
    await axios
      .get("/device/manufacturerSkuBanks", Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData?.meta?.code === 200) {
          const responseData = resultData.data;
          setManufacturerData(responseData);
          dispatch(setManufacturerSKUList(responseData));
        } else {
          setManufacturerData([]);
          dispatch(setManufacturerSKUList([]));
        }
      });
  };

  const fetchLicensesData = async () => {
    let licensesLoadSuccess = await setLicenses(`/user/orgs/${orgId}/licenses`);

    if (licensesLoadSuccess?.status === "success") {
      setLicensesData(getLicenses());
    }
  };

  const getLicensesStatus = (device) => {
    const deviceLicense = licensesData.filter(
      (license) => license.deviceId === device.deviceId
    )[0];
    if (
      deviceLicense?.expiryDate &&
      Utils.getDateDifferenceInDays(
        deviceLicense?.expiryDate,
        moment().valueOf()
      ) <= 0
    ) {
      return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED;
    } else {
      if (deviceLicense?.licenseStatus === "ASSIGNED_ACTIVATION_PENDING") {
        return (
          constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING +
          " " +
          moment(deviceLicense?.activeDate).format("MMM Do YYYY")
        );
      } else if (
        deviceLicense?.licenseStatus !== "UNASSIGNED" &&
        deviceLicense?.expiringSoonInDays &&
        Utils.getDateDifferenceInDays(
          deviceLicense.expiryDate,
          moment().valueOf()
        ) <= deviceLicense?.expiringSoonInDays &&
        Utils.getDateDifferenceInDays(
          deviceLicense.expiryDate,
          moment().valueOf()
        ) >= 0
      ) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON;
      } else if (
        deviceLicense?.licenseStatus === "ACTIVE_UNASSIGNED" ||
        deviceLicense?.licenseStatus === "UNASSIGNED"
      ) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE;
      } else if (deviceLicense?.licenseStatus === "ACTIVE") {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE;
      } else if (deviceLicense?.licenseStatus === "UNASSIGNED") {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE;
      } else {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE;
      }
    }
  };

  const getCapabiltiesForDevice = async (deviceData) => {
    if (deviceData?.capability) {
      try {
        const response = await fetch(deviceData?.capability?.url);
        if (response.status === 200) {
          const responseJson = await response.json();
          return responseJson;
        } else {
          return {};
        }
      } catch (error) {
        return {};
      }
    } else {
      return {};
    }
  };

  const showChannelDetails = async (data) => {
    const capDetails = await getCapabiltiesForDevice(data);
    localStorage.setItem("localStoreProperty", JSON.stringify(data.properties));
    dispatch(setDeviceInformation({ ...data, capDetails: capDetails }));
    data?.deviceId &&
      navigate(
        `/devices/channel-details.html?deviceId=${data?.deviceId}&orgId=${orgDetails?.orgId}`
      );
  };

  const handleScanNetworkForDeviceClick = async (device) => {
    setIsDiscoverPopupLoading(device?.deviceId);
    // await setNVRScannedDevices([]);
    // await setAllScannedDevices([]);
    axios
      .get(
        `device/orgs/${orgDetails?.orgId}/devices/${device?.deviceId}/v2`,
        Utils.requestHeader()
      )
      .then(async (response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === "200")
          ) {
            setIsDiscoverPopupLoading(null);
            dispatch(setAllMqttDataFromResponse(null));
            device.isExpanded = true;
            onCollapseExpandDevice(device);
            setShowScanNetworkModal(true);
            setGatewayDeviceData(responseData);
            dispatch(
              setNVRDeviceData({
                ...responseData,
                channelCount: manufacturerData?.find(
                  (item) => item.model === responseData.modelNumber
                )?.channelCount,
                childDevices: device.childDevices,
              })
            );
            await setNVRScannedDevices([]);
            await setAllScannedDevices([]);
          } else {
            setIsDiscoverPopupLoading(null);
            console.log("Error - ", responseMeta.userMsg);
          }
        }
      });
  };

  // for mobile responsive device
  const onClickRemoveDevice = (device) => {
    setSelectedDeviceToRemove(device);
    setIsDisplayRemoveDeviceModal(true);
  };
  const renameRecursive = (device, newName) => {
    if (device?.deviceId === renamingDevice?.deviceId) {
      device.deviceName = newName;
      return;
    }
    if (device?.childDevices?.length) {
      device?.childDevices?.forEach((childDevice) => {
        renameRecursive(childDevice, newName);
      });
    }
  };
  const onRenameDevice = async (newName) => {
    setIsRenamingDevice(true);
    let updateAPIData = await renameChannelAPI(orgId, renamingDevice, newName);

    setIsRenamingDevice(false);
    if (updateAPIData?.deviceName) {
      const rawFilteredDeviceData = structuredClone(filteredDeviceData);
      rawFilteredDeviceData.forEach((device) => {
        renameRecursive(device, updateAPIData?.deviceName);
      });
      setFilteredDeviceData(rawFilteredDeviceData);
      setRenamingDevice(null);
      setRenamedDeviceName(null);
      setIsCheckForSearchCSS(!isCheckForSearchCSS);
      setSearchedText(searchText);
    }
  };

  const onRemoveDevice = async () => {
    setIsDisplayRemoveDeviceModal(false);
    setIsRemovingDevice(true);
    let updateAPIResponse = await removeChannelAPI(
      orgId,
      selectedDeviceToRemove
    );

    setIsRemovingDevice(false);
    if (updateAPIResponse?.code === 200 || updateAPIResponse?.code === 2303) {
      if (pageDetails?.page) {
        setPageDetails(defaultPageDetails);
      } else {
        fetchGatewayData(searchText);
      }
      setSelectedDeviceToRemove(null);
    }
  };
  const collapseRecursive = (device, deviceCollapsed) => {
    if (device?.deviceId === deviceCollapsed?.deviceId) {
      device.isExpanded = !device.isExpanded;
      device.childDevices = [];
      return;
    }
    if (device?.childDevices?.length) {
      device?.childDevices?.forEach((childDevice) => {
        collapseRecursive(childDevice, deviceCollapsed);
      });
    }
  };

  const onCollapseExpandDevice = (deviceCollapsed) => {
    const rawFilteredDeviceData = structuredClone(filteredDeviceData);
    rawFilteredDeviceData.forEach((device) => {
      collapseRecursive(device, deviceCollapsed);
    });
    setFilteredDeviceData(rawFilteredDeviceData);
    if (!deviceCollapsed?.isExpanded) {
      deviceCollapsed.childPageDetails = newChildPageDetails;
      setExpandedParent(deviceCollapsed);
    }
  };

  const onSort = (heading) => {
    const rawPageDetails = structuredClone(pageDetails);

    if (rawPageDetails.orderBy === heading?.id) {
      rawPageDetails.isAsc = !pageDetails.isAsc;
    } else {
      rawPageDetails.isAsc = sassTrue;
    }
    rawPageDetails.orderBy = heading?.id;
    rawPageDetails.page = 0;
    setPageDetails(rawPageDetails);
    resetSelectedDevices();
  };

  const getLocationData = (locationId) => {
    if (!locationId) return;

    return locationDetails.find(
      (location) => location.locationId === locationId
    );
  };

  const onSelectLocationHandler = (evtKey) => {
    if (!evtKey) return;
    const locationData = getLocationData(evtKey);
    setSelectedLocation(locationData);
    dispatch(setDevicesSelectedLocation(locationData));
  };

  const onchangePageNo = (pageNo) => {
    const rawPageDetails = structuredClone(pageDetails);
    rawPageDetails.page = pageNo;
    setPageDetails(rawPageDetails);
  };

  const onClickReset = () => {
    setUpdateUserMessage(null);
    resetSelectedDevices();
    setSearchText("");
    setSearchedText("");
    if (
      JSON.stringify(pageDetails) === JSON.stringify(defaultPageDetails) &&
      JSON.stringify(filtersData) === JSON.stringify(defaultFilters)
    ) {
      fetchGatewayData("");
    } else {
      setPageDetails(defaultPageDetails);
      setFiltersData(defaultFilters);
    }
  };

  useEffect(() => {
    const list = [];
    filteredDeviceData?.forEach((device) => {
      if (
        device &&
        device.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        device?.deviceStatus !== constants.DEVICES_DEACTIVATED_DEVICE_STATUS &&
        getCustomerOrgPolicies()?.manage_device_settings
      ) {
        list.push(device?.deviceId);
      }
    });
    setSelectEligibleDevices(list);
  }, [filteredDeviceData, isEditMode]);

  const onSelectAllDevices = () => {
    const list = [];
    filteredDeviceData?.forEach((device) => {
      if (
        device &&
        device.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        device?.deviceStatus !== constants.DEVICES_DEACTIVATED_DEVICE_STATUS &&
        getCustomerOrgPolicies()?.manage_device_settings
      ) {
        list.push(device?.deviceId);
      }
    });
    setSelectedDevices(list);
    checkAnyD2CInSelectedDevices(list);
    resetPartialSelectionStatus();
    setUpdateUserMessage(null);
  };

  const statusOfAllDevicesSelected = () => {
    const list = [];
    filteredDeviceData?.forEach((device) => {
      if (
        device &&
        device.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        device?.deviceStatus !== constants.DEVICES_DEACTIVATED_DEVICE_STATUS &&
        getCustomerOrgPolicies()?.manage_device_settings &&
        selectedDevices.includes(device?.deviceId)
      ) {
        list.push(device?.deviceId);
      }
    });
    return (
      selectEligibleDevices?.length &&
      list?.length === selectEligibleDevices?.length
    );
  };

  const resetSelectedDevices = () => {
    setSelectedDevices([]);
    setIsD2CInSelectedDevices(false);
  };

  const partialCheckRecursive = (rawDevice, device, selectedDeviceList) => {
    if (
      (rawDevice?.deviceId === device?.gatewayId ||
        rawDevice?.deviceId === device?.parentId) &&
      device?.stepCount - rawDevice?.stepCount === 1
    ) {
      let status = false;
      if (rawDevice?.childDevices?.length) {
        rawDevice?.childDevices?.forEach((childDevice) => {
          if (selectedDeviceList.includes(childDevice?.deviceId)) {
            status = true;
          }
        });
      }
      rawDevice.partial = status;
      if (status) {
        return;
      }
    }
    if (rawDevice?.childDevices?.length) {
      rawDevice?.childDevices?.forEach((childDevice) => {
        childDevice = partialCheckRecursive(
          childDevice,
          device,
          selectedDeviceList
        );
      });
    }
  };

  const resetPartialSelectionStatus = () => {
    const parcialResetRecursive = (rawDevice) => {
      rawDevice.partial = false;

      if (rawDevice?.childDevices?.length) {
        rawDevice?.childDevices?.forEach((childDevice) => {
          childDevice = parcialResetRecursive(childDevice);
        });
      }
      return rawDevice;
    };
    const rawFilteredDeviceData = structuredClone(filteredDeviceData);
    rawFilteredDeviceData.forEach((rawDevice) => {
      rawDevice = parcialResetRecursive(rawDevice);
    });
    setFilteredDeviceData(rawFilteredDeviceData);
  };

  const findRecursiveDeviceByDeviceId = (
    device,
    selectedDeviceId,
    filteredDevice
  ) => {
    if (device?.deviceId === selectedDeviceId) {
      filteredDevice = device;
    }
    if (device?.childDevices?.length && !filteredDevice) {
      device?.childDevices?.forEach((childDevice) => {
        filteredDevice = findRecursiveDeviceByDeviceId(
          childDevice,
          selectedDeviceId,
          filteredDevice
        );
      });
    }
    return filteredDevice;
  };

  const checkAnyD2CInSelectedDevices = (selectedDevices) => {
    let status = false;
    selectedDevices.forEach((selectedDeviceId) => {
      let filteredDevice = null;
      filteredDeviceData.forEach((device) => {
        if (!filteredDevice) {
          filteredDevice = findRecursiveDeviceByDeviceId(
            device,
            selectedDeviceId,
            filteredDevice
          );
        }
      });
      if (filteredDevice) {
        status =
          status ||
          filteredDevice?.deviceType?.toUpperCase() === DeviceTypeEnum.IPCam ||
          filteredDevice.deviceType.toLowerCase() === "nwc" ||
          filteredDevice.deviceType.toLowerCase() === "onvifcam" ||
          filteredDevice.deviceType === DeviceTypeEnum.WAVE;
      }
    });
    setIsD2CInSelectedDevices(status);
  };

  const onChanegCheckBox = (e, device) => {
    const { checked } = e.target;
    let checkList = structuredClone(selectedDevices);
    if (checked) {
      checkList.push(device?.deviceId);
    } else {
      const index = checkList.indexOf(device?.deviceId, 0);
      if (index > -1) {
        checkList.splice(index, 1);
      }
    }
    const newList = [...new Set(checkList)];
    setSelectedDevices(newList);
    checkAnyD2CInSelectedDevices(newList);
    if (
      device?.deviceType?.toUpperCase() !== DeviceTypeEnum.NVR &&
      device?.deviceType?.toUpperCase() !== DeviceTypeEnum.DMSERVER
    ) {
      const rawFilteredDeviceData = structuredClone(filteredDeviceData);
      rawFilteredDeviceData.forEach((rawDevice) => {
        rawDevice = partialCheckRecursive(rawDevice, device, newList);
      });
      setFilteredDeviceData(rawFilteredDeviceData);
    }
    setUpdateUserMessage(null);
  };

  const updateDeviceLocationNArea = async (location, area) => {
    setShowLoader(true);
    let locationAreaAPIData = null;
    if (isD2CInSelectedDevices) {
      locationAreaAPIData = await updateDeviceAreaAPI(
        orgId,
        location,
        area,
        selectedDevices
      );
    } else {
      locationAreaAPIData = await updateDeviceLoctaionAreaAPI(
        orgId,
        location,
        area,
        selectedDevices
      );
    }

    if (locationAreaAPIData?.devices) {
      setUpdateUserMessage(locationAreaAPIData?.devices);
      if (JSON.stringify(pageDetails) === JSON.stringify(defaultPageDetails)) {
        fetchGatewayData(searchText);
      } else {
        setPageDetails(defaultPageDetails);
      }
      if (!locationAreaAPIData?.devices?.failed?.length) {
        resetSelectedDevices();
      }
    } else {
      setShowLoader(false);
    }
  };

  const displayDataWithTooltip = (value, search) => {
    let chunks = [];
    if (search) {
      chunks = highlightWords({
        text: value,
        query: searchedText,
        matchExactly: true,
      });
    }
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip>{value}</Tooltip>}>
        <span className={search ? "searchStyles" : ""}>
          {searchedText
            ? chunks.map(({ text, match, key }) =>
                match ? (
                  <span className="searched-text" key={key}>
                    {text}
                  </span>
                ) : (
                  <span key={key}>{text}</span>
                )
              )
            : value}
        </span>
      </OverlayTrigger>
    );
  };

  const getClassName = (deviceApps, itemId, deviceStatus) => {
    if (
      itemId === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD &&
      deviceStatus !== "PENDING_CLAIM"
    ) {
      return deviceApps?.[
        itemId === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
          ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
          : itemId
      ]
        ? "appActiveWithoutOnCloud"
        : "appDisableWithoutOnCloud";
    } else {
      return deviceApps?.[
        itemId === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
          ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
          : itemId
      ]
        ? "appActiveWithoutOnCloud"
        : "appDisableWithoutOnCloud";
    }
  };

  const displayApplicationsColumnDetails = (device) => {
    return (
      <div className="d-flex align-center justify-content-between">
        {device?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR ||
        device?.deviceType?.toUpperCase() === DeviceTypeEnum.DMSERVER ||
        device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE ? (
          <PrimaryButton
            className={`device-btn-list btn-primary ${
              isEditMode ? "hide" : ""
            }`}
            type="button"
            width="165px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue("--primary_40")}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              "--brand_white"
            )}
            fontSize="14px"
            height="32px"
            fontWeight="400"
            borderRadius="4px"
            disabled={
              device?.deviceStatus ===
                constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
              device?.deviceStatus ===
                constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS ||
              device.connectionStatus ===
                constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
              !getCustomerOrgPolicies()?.install_device
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleScanNetworkForDeviceClick(device);
            }}
            loader={isDiscoverPopupLoading === device.deviceId}
          >
            {constants.DISCOVERED_DEVICES_SCAN_BUTTON_TEXT}
          </PrimaryButton>
        ) : (
          <div>
            {appList?.map((item, index) => (
              <>
                {Object.keys(device?.apps).includes(
                  item.id ===
                    constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
                    ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
                    : item.id
                ) && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{item.name}</Tooltip>}
                  >
                    <img
                      alt=""
                      className={`deviceIcon ${getClassName(
                        device.apps,
                        item.id,
                        device?.deviceStatus
                      )} ${index + 1 === appList?.length ? "mx-0" : ""}`}
                      src={item.gicon}
                    />
                  </OverlayTrigger>
                )}
              </>
            ))}
          </div>
        )}
        {(!device?.stepCount ||
          (device?.stepCount &&
            device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE)) &&
        !isEditMode ? (
          <IoSettingsOutline
            size={24}
            width={24}
            height={24}
            className={"setting-icon"}
            color="#0000009C"
            onClick={(e) => {
              e.preventDefault();
              showChannelDetails(device);
            }}
          />
        ) : isEditMode &&
          customerOrgPolicies?.filter(
            (item) => item.policyName === "delete_device"
          ) ? (
          isRemovingDevice &&
          selectedDeviceToRemove?.deviceId === device?.deviceId ? (
            <span className="margin-lt-20">
              <SiteSpinner
                width="16px"
                height="16px"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--brand_black")}
              />
            </span>
          ) : (
            <span className="hover-icons">
              <PiTrash
                size={24}
                className={"setting-icon"}
                color="#0000009C"
                onClick={() => onClickRemoveDevice(device)}
                role="button"
              />
            </span>
          )
        ) : null}
      </div>
    );
  };
  const displayModalColumnDetails = (device) => {
    return (
      <>
        {displayDataWithTooltip(device.modelNumber, true)}
        {device?.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS &&
        device.isFirmwareUpgradeAvailable &&
        device?.deviceType?.toUpperCase() !== DeviceTypeEnum.DMSERVER ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Firmware update available</Tooltip>}
          >
            <span className="fw-update-icon">FW</span>
          </OverlayTrigger>
        ) : null}
      </>
    );
  };

  const getDeviceStatusClassname = (device) => {
    if (
      device.deviceStatus?.toUpperCase() ===
      constants.DEVICES_DEACTIVATED_DEVICE_STATUS
    ) {
      return "paginated-deactiavted-device";
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS
    ) {
      return "paginated-online-device";
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_CLAIMING_STATUS
    ) {
      return "paginated-claiming-device";
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_OFFLINE_STATUS
    ) {
      return "paginated-offline-device";
    }
  };
  const displayRowAccordian = (device) => {
    return (
      <>
        {expandedParent?.deviceId === device?.deviceId &&
        !device?.childDevices?.length ? (
          <span className={`d-flex`}>
            <SiteSpinner
              width="16px"
              height="16px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--brand_black")}
            />
          </span>
        ) : device.isExpanded ? (
          <HiOutlineChevronDown
            size={20}
            color="#747E87"
            className={` ${
              device?.count > 2 ||
              (device?.count > 1 &&
                (device?.deviceType?.toUpperCase() ===
                  DeviceTypeEnum.DMSERVER ||
                  device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE))
                ? ""
                : "hide"
            }`}
            onClick={() => {
              onCollapseExpandDevice(device);
            }}
            role="button"
          />
        ) : (
          <HiOutlineChevronRight
            size={20}
            color="#747E87"
            className={`${
              device?.count > 2 ||
              (device?.count > 1 &&
                (device?.deviceType?.toUpperCase() ===
                  DeviceTypeEnum.DMSERVER ||
                  device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE))
                ? ""
                : "hide"
            }`}
            onClick={() => {
              onCollapseExpandDevice(device);
            }}
            role="button"
          />
        )}
      </>
    );
  };
  const displayRowEditCheckBox = (device) => {
    return (
      <>
        {isEditMode ? (
          <td>
            {(device?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR ||
              device?.deviceType?.toUpperCase() === DeviceTypeEnum.DMSERVER ||
              device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE) &&
            device?.partial &&
            !selectedDevices.includes(device?.deviceId) ? (
              <span
                className="edit-checkbox"
                role="button"
                onClick={() =>
                  onChanegCheckBox(
                    {
                      target: {
                        checked: !selectedDevices.includes(device?.deviceId),
                      },
                    },
                    device
                  )
                }
              >
                <input
                  type="checkbox"
                  checked={device?.partial}
                  className={`edit-checkbox
                  ${
                    device.connectionStatus !==
                      constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                    device?.deviceStatus !==
                      constants.DEVICES_CLAIMED_DEVICE_STATUS ||
                    !getCustomerOrgPolicies()?.manage_device_settings
                      ? "disabled"
                      : ""
                  }
                `}
                  disabled={
                    device.connectionStatus !==
                      constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                    device?.deviceStatus !==
                      constants.DEVICES_CLAIMED_DEVICE_STATUS ||
                    !getCustomerOrgPolicies()?.manage_device_settings
                  }
                  onChange={(e) =>
                    onChanegCheckBox(
                      {
                        target: {
                          checked: !selectedDevices.includes(device?.deviceId),
                        },
                      },
                      device
                    )
                  }
                  role="button"
                />
                <span className="edit-inner-checkbox"></span>
              </span>
            ) : (
              <span className="delete-checkbox">
                <input
                  type="checkbox"
                  checked={selectedDevices.includes(device?.deviceId)}
                  className={`delete-checkbox
                  ${
                    device.connectionStatus !==
                      constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                    device?.deviceStatus !==
                      constants.DEVICES_CLAIMED_DEVICE_STATUS ||
                    !getCustomerOrgPolicies()?.manage_device_settings
                      ? "disabled"
                      : ""
                  }
                `}
                  disabled={
                    device.connectionStatus !==
                      constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                    device?.deviceStatus !==
                      constants.DEVICES_CLAIMED_DEVICE_STATUS ||
                    !getCustomerOrgPolicies()?.manage_device_settings
                  }
                  onChange={(e) => onChanegCheckBox(e, device)}
                  role="button"
                />
              </span>
            )}
          </td>
        ) : null}
      </>
    );
  };

  const displayDeviceNameColumnDetails = (device) => {
    const chunks = highlightWords({
      text: device?.deviceName,
      query: searchedText,
      matchExactly: true,
    });
    return (
      <>
        {device?.stepCount ? (
          <StepBottomIcon
            size={20}
            color="#0000004A"
            className={`margin-rt`}
            style={{
              marginLeft:
                device?.stepCount > 1 ? `${device?.stepCount * 20}px` : "",
            }}
          />
        ) : null}
        <img
          alt=""
          className={`margin-rt device-icon-width`}
          onError={(e) => (e.target.src = `${DefaultDevice}`)}
          src={device?.imageURL ? device?.imageURL : DefaultDevice}
        />
        {isEditMode && renamingDevice?.deviceId === device?.deviceId ? (
          <span className="edit-name-container">
            <input
              autoFocus={true}
              maxLength={40}
              type={"text"}
              placeholder={"Name"}
              className={`rename-input ${isRenamingDevice ? "renaming" : ""}`}
              defaultValue={device?.deviceName}
              onChange={(e) => setRenamedDeviceName(e?.target?.value)}
              ref={renameInputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter" && renamedDeviceName) {
                  onRenameDevice(e.target.value);
                }
              }}
            />

            {isRenamingDevice ? (
              <SiteSpinner
                width="16px"
                height="16px"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--brand_black")}
              />
            ) : (
              <IoCloseCircle
                className="cross-icon"
                size={16}
                onClick={() => {
                  setRenamingDevice(null);
                  setRenamedDeviceName(null);
                }}
                color="#747E87"
                role="button"
              />
            )}
          </span>
        ) : (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{device.deviceName}</Tooltip>}
          >
            <span className={"searchStyles"}>
              {chunks.map(({ text, match, key }) =>
                match ? (
                  <span className="searched-text" key={key}>
                    {text}
                  </span>
                ) : (
                  <span key={key}>{text}</span>
                )
              )}
            </span>
          </OverlayTrigger>
        )}
        {isEditMode && renamingDevice?.deviceId !== device?.deviceId ? (
          <span className="hover-icons">
            <GoPencil
              size={16}
              role="button"
              color="#0000007D"
              className={"mx-1 mb-1"}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setRenamingDevice(device);
                setRenamedDeviceName(device?.deviceName);
              }}
            />
          </span>
        ) : null}
      </>
    );
  };

  const displayDeviceRowDetails = (device) => {
    return (
      <tr
        className={`row-bg ${device?.isExpanded ? "expanded-parent-row" : ""}`}
      >
        <td>{displayRowAccordian(device)}</td>
        {isEditMode ? displayRowEditCheckBox(device) : null}
        <td
          className={`
            ${
              device?.count > 2 ||
              device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE ||
              !device?.stepCount
                ? "parent-name"
                : "device-name-color"
            }
             ${
               isEditMode && renamingDevice?.deviceId === device?.deviceId
                 ? "paginated-device-name"
                 : isEditMode
                 ? "truncated-device-name"
                 : ""
             }
          `}
        >
          {displayDeviceNameColumnDetails(device)}
        </td>
        <td>{displayModalColumnDetails(device)}</td>
        <td>{displayDataWithTooltip(device.serialNo, true)}</td>
        <td className={getDeviceStatusClassname(device)}>
          {displayDataWithTooltip(device.displayDeviceStatus)}
        </td>
        <td>{displayDataWithTooltip(device.displayLocation)}</td>
        <td>
          {displayDataWithTooltip(
            device?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR ||
              device?.deviceType?.toUpperCase() === DeviceTypeEnum.DMSERVER ||
              device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE
              ? "-"
              : device.displayArea
          )}
        </td>
        <td>{displayApplicationsColumnDetails(device)}</td>
      </tr>
    );
  };

  const locationDropdownContent =
    locationDetails?.length > 0 ? (
      locationDetails?.map((location) => (
        <Dropdown.Item
          key={`location-${location.locationId}`}
          className={`area-selector-menu-item ${
            selectedLocation?.locationId === location.locationId
              ? "selected"
              : ""
          }`}
          eventKey={location.locationId}
        >
          {location.locationName}
        </Dropdown.Item>
      ))
    ) : (
      <Dropdown.Item
        key={`location-${0}`}
        className={`area-selector-menu-item`}
        eventKey={undefined}
      >
        {constants.LOCATION_DROPDOWN_NO_LOCATION_DATA_TEXT}
      </Dropdown.Item>
    );

  const displayChildDetails = (device) => {
    return (
      <>
        {device?.isExpanded && device?.childDevices?.length
          ? device?.childDevices?.map((childDevice) => (
              <>
                {displayDeviceRowDetails(childDevice)}
                <>
                  {childDevice?.childDevices?.length ? (
                    <>{displayChildDetails(childDevice)}</>
                  ) : null}
                </>
              </>
            ))
          : null}

        {device?.isExpanded &&
        device?.childPageDetails?.page + 1 <
          device?.childPageDetails?.totalPages &&
        device?.childDevices?.length ? (
          <tr>
            <div
              className={`load-more ${
                device?.childPageDetails?.page + 1 >=
                device?.childPageDetails?.totalPages
                  ? "load-more-disabled"
                  : ""
              } load-more-child`}
              role="button"
              onClick={() => {
                if (
                  device?.childPageDetails?.page + 1 <
                  device?.childPageDetails?.totalPages
                ) {
                  setExpandedParent(device);
                }
              }}
              style={{
                marginLeft: device?.stepCount
                  ? `${device?.stepCount * 40 + 40}px`
                  : "",
              }}
            >
              {expandedParent?.deviceId === device?.deviceId &&
              device?.childDevices?.length ? (
                <span className="margin-rt-4 d-flex">
                  <SiteSpinner
                    width="16px"
                    height="16px"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--brand_black")}
                  />
                </span>
              ) : (
                <BsPlus
                  size={20}
                  disabled={
                    device?.childPageDetails?.page + 1 >=
                    device?.childPageDetails?.totalPages
                  }
                />
              )}
              {constants.DEVICES_HEADER_BUTTON_LOAD_MORE}
            </div>
          </tr>
        ) : null}
      </>
    );
  };

  const displayGatewayDetails = () => {
    return (
      <>
        {!filteredDeviceData?.length && showLoader ? (
          <tr>
            <td rowSpan={10} colSpan={8} className="loader-container">
              <SiteSpinner width="60px" height="60px" />
            </td>
          </tr>
        ) : filteredDeviceData?.length ? (
          <>
            {filteredDeviceData?.map((device, index) => (
              <>
                {index < (pageDetails?.page + 1) * 100
                  ? displayDeviceRowDetails(device)
                  : null}
                {displayChildDetails(device)}
              </>
            ))}
            {pageDetails?.page + 1 < totalPages ? (
              <tr>
                <div
                  className={`load-more ${
                    pageDetails?.page + 1 >= totalPages
                      ? "load-more-disabled"
                      : ""
                  } load-more-parent`}
                  role="button"
                  onClick={() => {
                    if (pageDetails?.page + 1 < totalPages) {
                      onchangePageNo(pageDetails.page + 1);
                      setShowLoaderMore(true);
                    }
                  }}
                >
                  {showLoaderMore ? (
                    <span className="margin-rt-4 d-flex">
                      <SiteSpinner
                        width="16px"
                        height="16px"
                        backgroundColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--brand_black")}
                      />
                    </span>
                  ) : (
                    <BsPlus
                      size={20}
                      disabled={pageDetails?.page + 1 >= totalPages}
                    />
                  )}
                  {constants.DEVICES_HEADER_BUTTON_LOAD_MORE}
                </div>
              </tr>
            ) : null}
          </>
        ) : !showLoader ? (
          <tr>
            <td rowSpan={10} colSpan={8} className="no-device-container">
              <img
                alt="exclamation"
                src={CircleExclamation}
                width={"60px"}
                height={"60px"}
              />
              <div className="no-device-content mt-3">
                <div className="no-device-heading">
                  {constants.SCAN_NETWORK_NO_DEVICE_TITLE}
                </div>
                <div className="no-device-text">
                  {constants.DEVICES_TABLE_NO_DEVICES_TEXT}
                </div>
              </div>
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  const displayHeaderDetails = (headerList) => {
    return (
      <>
        <tr>
          {headerList?.map((heading) => (
            <th>
              <div className={heading.id}>
                {heading?.displayName}
                {heading?.id === "arrow" ||
                heading?.id === "applications-linked" ||
                heading?.id === "location" ||
                heading?.id === "area" ? null : heading?.id === "checkbox" ? (
                  <div className="delete-checkbox">
                    <input
                      type="checkbox"
                      className="delete-checkbox "
                      onChange={(e) => {
                        const { checked } = e.target;
                        if (checked) {
                          onSelectAllDevices();
                        } else {
                          resetSelectedDevices();
                        }
                      }}
                      checked={statusOfAllDevicesSelected()}
                      role="button"
                    />
                  </div>
                ) : pageDetails.orderBy === heading?.id && pageDetails.isAsc ? (
                  <IoIosArrowRoundDown
                    size={20}
                    color="#0000004A"
                    role="button"
                    onClick={() => onSort(heading)}
                  />
                ) : pageDetails.orderBy === heading?.id &&
                  !pageDetails.isAsc ? (
                  <IoIosArrowRoundUp
                    size={20}
                    color="#0000004A"
                    role="button"
                    onClick={() => onSort(heading)}
                  />
                ) : (
                  <RxCaretSort
                    size={20}
                    color="#0000004A"
                    role="button"
                    onClick={() => onSort(heading)}
                  />
                )}
              </div>
            </th>
          ))}
        </tr>
      </>
    );
  };

  const LoaderOverlay = () => {
    return <div className="position-fixed l-48 loader-style"></div>;
  };

  return (
    <div className="App">
      <Header breadcrumbData={breadList} className="Desktop" />
      <LoadingOverlay
        active={showLoader && !showLoaderMore}
        spinner={LoaderOverlay()}
      >
        <div className="paginated-device-page">
          <div className="paginated-devices">
            <div className="devices-header">
              <div className="devices-title">
                {isEditMode ? (
                  <div
                    role="button"
                    onClick={() => {
                      setIsEditMode(false);
                      resetSelectedDevices();
                      setSelectedDeviceToRemove(null);
                      setRenamingDevice(null);
                      setRenamedDeviceName(null);
                      setIsCheckForSearchCSS(!isCheckForSearchCSS);
                    }}
                  >
                    <HiOutlineChevronLeft
                      size={24}
                      color="#000000"
                      className="margin-rt"
                      role="button"
                    />
                    {constants.DEVICES_HEADER_BUTTON_EDIT}
                  </div>
                ) : (
                  constants.DEVICES_PAGE_TITLE
                )}
              </div>
              <BreadcrumbList
                list={Utils.CheckBreadcrumbForUserRole(
                  isEditMode ? editModeBreadList : breadList
                )}
                isFromEditDevices={isEditMode}
                callBack={() => {
                  setIsEditMode(false);
                  resetSelectedDevices();
                  setIsCheckForSearchCSS(!isCheckForSearchCSS);
                }}
              />
            </div>
            {discovredDevciesCount ? (
              <div className="discovred-devices-container">
                <div className="d-flex">
                  <HiOutlineInformationCircle size={20} />
                  <div className="deiscovered-device-message">
                    {constants.DISCOVERED_DEVICES_MESSAGE}
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    className="pending-count"
                    onClick={() => {
                      navigate(
                        `/devices/discovered-devices.html?orgId=${orgDetails?.orgId}`
                      );
                    }}
                    role="button"
                  >
                    {discovredDevciesCount}{" "}
                    {constants.DISCOVERED_DEVICES_PENDING_COUNT}
                    <HiOutlineChevronRight size={20} color="#F37321" />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="devices-filters">
              <div className="left-side">
                {isEditMode ? (
                  <div
                    className={`reload-filter-edit ${
                      !selectedDevices?.length ? "disabled" : ""
                    }`}
                    role="button"
                    onClick={() => {
                      setIsDisplayLocationAreaModal(true);
                      setUpdateUserMessage(null);
                    }}
                  >
                    <TbMapPin size={18} />
                    {constants.DEVICES_HEADER_BUTTON_SET_LOCATION}
                  </div>
                ) : (
                  <Dropdown
                    className="area-selector"
                    onSelect={onSelectLocationHandler}
                  >
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id="dropdown-basic"
                      className="area-selector-toggle"
                    >
                      <div className="area-selected-wrapper">
                        <span className="area-selected-name">
                          {selectedLocation?.locationName}
                        </span>
                      </div>
                      <HiOutlineChevronDown size={20} color="#747E87" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="area-selector-menu" flip={false}>
                      {locationDropdownContent}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {!isEditMode ? (
                  <div
                    className="reload-filter-edit"
                    role="button"
                    onClick={() => {
                      setIsEditMode(true);
                      resetSelectedDevices();
                      setSelectedDeviceToRemove(null);
                      setIsCheckForSearchCSS(!isCheckForSearchCSS);
                    }}
                  >
                    <GoPencil size={16} />
                    {constants.DEVICES_HEADER_BUTTON_EDIT}
                  </div>
                ) : null}
                <div className="count-container">
                  <div className="display-bold">
                    {filteredDeviceData?.length}
                  </div>
                  <div>of {totalDevices}</div>
                </div>
                {isEditMode ? (
                  <div className="count-container">
                    ({" "}
                    <div className="paginated-claiming-device">
                      {selectedDevices?.length}{" "}
                    </div>
                    <div> {constants.DEVICES_HEADER_SELECTED}</div>
                  </div>
                ) : null}
                {updateUserMessage ? (
                  <div className="update-location-usermsg-container">
                    {updateUserMessage?.succeed?.length &&
                    !updateUserMessage?.failed?.length ? (
                      <div className="success">
                        {updateUserMessage?.succeed?.length === 1
                          ? constants.DEVICES_HEADER_SUCCESS1.replace(
                              "{deviceCount}",
                              updateUserMessage?.succeed?.length
                            )
                          : constants.DEVICES_HEADER_SUCCESS2.replace(
                              "{deviceCount}",
                              updateUserMessage?.succeed?.length
                            )}
                      </div>
                    ) : !updateUserMessage?.succeed?.length &&
                      updateUserMessage?.failed?.length ? (
                      <div className="failure">
                        {updateUserMessage?.succeed?.length}{" "}
                        {constants.DEVICES_HEADER_FAILED}
                      </div>
                    ) : updateUserMessage?.succeed?.length &&
                      updateUserMessage?.failed?.length ? (
                      <>
                        <div className="success">
                          {updateUserMessage?.succeed?.length === 1
                            ? constants.DEVICES_HEADER_SUCCESS1.replace(
                                "{deviceCount}",
                                updateUserMessage?.succeed?.length
                              )
                            : constants.DEVICES_HEADER_SUCCESS2.replace(
                                "{deviceCount}",
                                updateUserMessage?.succeed?.length
                              )}
                        </div>
                        <div className="failure">
                          [ {updateUserMessage?.succeed?.length}{" "}
                          {constants.DEVICES_HEADER_FAILED}]
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className="right-side">
                <div className="search-container">
                  <IoIosSearch
                    className="search-icon"
                    size={24}
                    role="button"
                    onClick={() => {
                      resetSelectedDevices();
                      setUpdateUserMessage(null);
                      if (pageDetails?.page) {
                        setPageDetails(defaultPageDetails);
                      } else {
                        fetchGatewayData(searchText.trim());
                      }
                    }}
                  />
                  <input
                    type={"text"}
                    placeholder={"Search"}
                    className="search-input"
                    value={searchText}
                    onChange={(e) => setSearchText(e?.target?.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        resetSelectedDevices();
                        setUpdateUserMessage(null);
                        if (pageDetails?.page) {
                          setPageDetails(defaultPageDetails);
                        } else {
                          fetchGatewayData(e?.target?.value);
                        }
                      }
                    }}
                  />
                  {searchText ? (
                    <IoCloseCircle
                      className="search-close"
                      size={16}
                      onClick={() => {
                        setSearchText("");
                        setSearchedText("");
                        fetchGatewayData("");
                      }}
                      color="#747E87"
                      role="button"
                    />
                  ) : null}
                </div>
                <div
                  className="reload-filter-edit"
                  role="button"
                  onClick={() => {
                    setIsDisplayFiltersModal(true);
                    setUpdateUserMessage(null);
                  }}
                >
                  <CiFilter size={20} />
                  {constants.DEVICES_HEADER_BUTTON_FILTER}
                </div>
                <div
                  className="reload-filter-edit"
                  role="button"
                  onClick={() => onClickReset()}
                >
                  <TfiReload size={16} />
                  {constants.DEVICES_HEADER_BUTTON_RELOAD}
                </div>
              </div>
            </div>
            {filteredDeviceData?.length && showLoader && !showLoaderMore ? (
              <div className="position-absolute screen-loader-container l-48 t-52">
                <SiteSpinner width="60px" height="60px" />
              </div>
            ) : null}
            {customerOrgPolicies &&
              customerOrgPolicies?.filter(
                (item) => item.policyName === "view_device"
              ) && (
                <>
                  {isEditMode ? (
                    <EditDevices
                      displayHeaderDetails={displayHeaderDetails}
                      displayGatewayDetails={displayGatewayDetails}
                    />
                  ) : (
                    <ResizableTable
                      widths={[29, 392, 200, 296, 120, 140, 140, 240]}
                      minWidths={[29, 292, 170, 170, 110, 110, 110, 240]}
                      minWidth={29}
                      disabledColumns={[0, 6]}
                      displayHeaderDetails={() =>
                        displayHeaderDetails(paginatedHeaderList)
                      }
                      displayGatewayDetails={() => displayGatewayDetails()}
                    />
                  )}
                </>
              )}
          </div>
          <SiteModal
            modalTitle=""
            modalHeader={true}
            showModal={isDisplayFiltersModal}
            hideModal={() => {
              setIsDisplayFiltersModal(false);
            }}
            classes="device-filter-modal"
            key="device-filter-modal"
            wrapperClass="device-filter-modal"
            size={"lg"}
          >
            <DevicesFilters
              closeModal={() => setIsDisplayFiltersModal(false)}
              apiConnectTypes={deviceTypes}
              setFiltersData={(e) => {
                setPageDetails(defaultPageDetails);
                setFiltersData(e);
                resetSelectedDevices();
              }}
              filtersData={filtersData}
            />
          </SiteModal>
          <SiteModal
            modalTitle={constants.DEVICES_HEADER_BUTTON_SET_LOCATION}
            showModal={isDisplayLocationAreaModal}
            hideModal={() => {
              setIsDisplayLocationAreaModal(false);
            }}
            classes="location-area-selector-modal"
            key="location-area-selector-modal"
            wrapperClass="location-area-selector-modal"
          >
            <LocationAreaSelectorModal
              closeModal={() => {
                setIsDisplayLocationAreaModal(false);
              }}
              updateDeviceLocationNArea={updateDeviceLocationNArea}
              mainSelectedLocation={selectedLocation}
              isD2CInSelectedDevices={isD2CInSelectedDevices}
            />
          </SiteModal>
          <SiteModal
            modalTitle={constants.DEVICES_MODAL_REMOVE_DEVICE}
            showModal={isDisplayRemoveDeviceModal}
            hideModal={() => {
              setIsDisplayRemoveDeviceModal(false);
              setSelectedDeviceToRemove(null);
            }}
            classes="location-area-selector-modal"
            key="remove-device-modal"
            wrapperClass="location-area-selector-modal"
          >
            <RemoveDeviceModal
              closeModal={() => {
                setIsDisplayRemoveDeviceModal(false);
                setSelectedDeviceToRemove(null);
              }}
              onRemoveDevice={onRemoveDevice}
              selectedDeviceToRemove={selectedDeviceToRemove}
            />
          </SiteModal>
          <SiteModal
            modalTitle={constants.SCAN_NETWORK_MODAL_TITLE}
            showModal={showScanNetworkModal}
            hideModal={setShowScanNetworkModal}
            classes="scan-network-modal"
            key="scan-network-modal"
            wrapperClass="scan-network-wrapper"
            size={"xl"}
            modalFooter={
              <div
                className="primary-btn dark"
                role="button"
                onClick={() => setShowScanNetworkModal(false)}
              >
                {constants.SCAN_NETWORK_CLOSE_BTN_LABEL}
              </div>
            }
          >
            {showScanNetworkModal && (
              <ScanNetworkModal gatewayDeviceData={gatewayDeviceData} />
            )}
          </SiteModal>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default Devices;
