import axios from "axios";
import { Utils } from "../../../../../helpers";

export const getGatewayDeviceCountAPI = async (orgId, gatewayId) => {
  try {
    const response = await axios.get(
      `/device/orgs/${orgId}/gateways/${gatewayId}/deviceCount`,
      Utils.requestHeader()
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error("Error!");
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const moveDeviceToHubAPI = async (orgDetails, payload, hubId) => {
  try {
    const response = await axios.put(
      `device/orgs/${orgDetails?.orgId}/devices/${hubId}/move/v3`,
      payload,
      Utils.requestHeader()
    );
    const data = await response.data;
    if (data.error) {
      throw new Error("Error!");
    }
    if (data.meta.code === 200 || data.meta.code === 2303) {
      return data;
    } else {
      return data.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};
