import { useEffect, useMemo, useState, useCallback } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import {
  NoDataBlock,
  PrimaryButton,
  SiteModal,
  SiteToast,
  SiteSpinner,
} from "../../components/common/";
import { constants, Utils } from "../../helpers/";
import OrgListTable from "../organizations/OrgListTable";
import { usePoliciesStore } from "../../store/policiesStore";
import { NewOrganization } from "./";
import { icontick, cross, error } from "../../assets/images";
import { BsExclamationLg } from "react-icons/bs";
import {
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
} from "react-icons/hi";
import { RxPlus } from "react-icons/rx";
import "./ManageOrganizations.scss";
import axios from "axios";
import { subscribeWithOrgIds } from "../../utils/connection/mqttConnection";
import { useLoggedInUserData } from "../../store/LoggedInAccountStore";
import moment from "moment";
import {
  getCustomerOrgData,
  getOrganizations,
} from "../../store/OrganizationsStoreIDB";
import { observerInstance } from "../../store/indexDB/observer";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import { useDispatch, useSelector } from "react-redux";
import {
  getLicenseAndDeviceData,
  setLicenseAndDeviceData,
} from "../../store/reducers/StreamingReducer";

function PartnerLandingPage() {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const token = keycloak.token;
  const locationData = localStorage.getItem("inviteData");
  const location = locationData && JSON.parse(locationData);
  const invitationData = location;
  const inviteCode = invitationData?.meta?.code;
  const inviteStatus = invitationData?.data?.accepted;
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const inviteTitle =
    inviteCode === 200
      ? inviteStatus
        ? constants.PARTNER_INVITE_ACCEPTED_TITLE
        : constants.PARTNER_INVITE_REJECTED_TITLE
      : inviteCode === 1032
      ? constants.PARTNER_INVITE_INVALID_TITLE
      : inviteCode === 1030
      ? constants.PARTNER_INVITE_PROCESSED_TITLE
      : inviteCode === 1031 || inviteCode === 1033
      ? constants.PARTNER_INVALID_INVITATION_TITLE
      : invitationData?.meta?.desc;
  const inviteSubTitle =
    inviteCode === 200
      ? inviteStatus
        ? `${invitationData?.data?.orgName} ${constants.PARTNER_INVITE_ACCEPTED_SUB_TITLE}`
        : `${invitationData?.data?.orgName} ${constants.PARTNER_INVITE_REJECTED_SUB_TITLE}`
      : inviteCode === 1030
      ? ""
      : inviteCode === 1032
      ? invitationData?.meta?.userMsg?.replace("Invalid Account.", "")
      : invitationData?.meta?.userMsg;

  const [partnerInvitiationStatus, setPartnerInvitiationStatus] = useState(
    invitationData && Object.keys(invitationData).length > 0 ? true : false
  );

  //=== Store get/set actions
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // const getOrganizations = useOrganizations((state) => state.getOrganizations);
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );

  const [showToast, setShowToast] = useState(false);

  const [userMsg, setUserMsg] = useState("");
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [fetchOrgsStatus, setFetchOrgsStatus] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [licensesListByOrgIds, setLicensesListByOrgIds] = useState([]);
  const [devicesCountByOrgIds, setDevicesCountByOrgIds] = useState([]);
  const licenseAndDeviceData = useSelector(getLicenseAndDeviceData);
  const [orgListWithLicences, setOrgListWithLicences] = useState();
  const [customerOrgDataList, setCustomerOrgDataList] = useState();
  const [isChanged, setIsChanged] = useState(false);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgListWithLicences(orgs);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === "customerOrgData") {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  //=== Get organizations data
  const fetchOrganizations = async (token) => {
    if (token) {
      setShowSpinner(true);
      setFetchOrgsStatus(false);
      await getOrganizations(`/partner/account/self`);
      setShowSpinner(false);
      //=== Subscribe the topics for all added customer organizations
      subscribeWithOrgIds(orgListWithLicences);
    } else {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    if (licensesListByOrgIds?.length > 0 && devicesCountByOrgIds?.length > 0) {
      const combineResult = licensesListByOrgIds?.map((licenseItem) => {
        const item = devicesCountByOrgIds?.find(
          (x) => x.orgId === licenseItem.orgId
        );
        return item
          ? {
              ...licenseItem,
              totalDevicesCount: item.totalDevicesCount,
              totalOfflineDevicesCount: item.totalOfflineDevicesCount,
            }
          : licenseItem;
      });
      dispatch(setLicenseAndDeviceData(combineResult));
    }
  }, [licensesListByOrgIds, devicesCountByOrgIds]);

  useEffect(() => {
    if (orgListWithLicences?.length > 0) {
      updateLicenseInfoForAllOrgs(orgListWithLicences);
      updateDevicesCountForAllOrgs(orgListWithLicences);
      setCustomerOrgDataList(orgListWithLicences);
    } else if (orgListWithLicences?.length === 0) {
      setCustomerOrgDataList(orgListWithLicences);
    }
  }, [JSON.stringify(orgListWithLicences)]);

  useEffect(() => {
    if (licenseAndDeviceData?.length > 0) {
      const list = orgListWithLicences?.map((org) => {
        const item = licenseAndDeviceData?.find((x) => x?.orgId === org?.orgId);

        return {
          ...org,
          expiredLicenseCount: item?.expiredLicenseCount || 0,
          expiringSoonLicenseCount: item?.expiringSoonLicenseCount || 0,
          cameraOfflineCount: `${item?.totalOfflineDevicesCount || 0} / ${
            item?.totalDevicesCount || 0
          }`,
        };
      });
      setCustomerOrgDataList(list);
    }
    setIsChanged(!isChanged);
  }, [licenseAndDeviceData]);

  useEffect(() => {
    if (customerOrgDataList) {
      setShowSpinner(false);
    }
  }, [customerOrgDataList]);

  const updateLicenseInfoForAllOrgs = (orgList) => {
    const licenseList = orgList?.map(async (org) => {
      if (org.role) {
        const url = `/user/orgs/${org?.orgId}/licenses`;
        const license = await axios
          .get(url, Utils.requestHeader())
          .then((res) => {
            if (res && res.data) {
              const respData = res.data;
              // Ensure we're only showing paid and eval licenses
              const paidAndEvalLicenses = respData.data.filter(
                (license) => license?.offeringType !== "ONBOARDING"
              );

              const tempLic = {
                orgId: org.orgId,
                expiredLicenseCount:
                  getExpiredLicenseCount(paidAndEvalLicenses),
                expiringSoonLicenseCount:
                  getExpiringSoonLicenseCount(paidAndEvalLicenses),
              };
              return tempLic;
            }
          });
        return license;
      }
    });
    Promise.all(licenseList).then((values) => {
      setLicensesListByOrgIds(values.filter(Boolean));
    });
  };

  const updateDevicesCountForAllOrgs = async (orgList) => {
    if (!orgList) return;

    const devicePromises = orgList.map(async (org) => {
      if (!org.role) return null;

      const url = `/device/orgs/${org.orgId}/devices`;
      try {
        const { data } = await axios.get(url, Utils.requestHeader());
        const respData = data?.data || [];

        const totalDevices =
          Utils.getTotalChildDevices(respData)?.filter(
            (device) =>
              device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS ||
              device.deviceStatus ===
                constants.DEVICES_DEACTIVATED_DEVICE_STATUS
          ) || [];

        const nvrDeviceList =
          Utils.getTotalParentDevices(respData)?.filter(
            (device) =>
              (device.deviceStatus ===
                constants.DEVICES_CLAIMED_DEVICE_STATUS ||
                device.deviceStatus ===
                  constants.DEVICES_DEACTIVATED_DEVICE_STATUS) &&
              (device.deviceType === "nvr" || device.deviceType === "dmserver")
          ) || [];

        const offlineNVRDevicesCount =
          nvrDeviceList.filter(
            (device) =>
              !device.connectionStatus ||
              device.connectionStatus ===
                constants.DEVICES_OFFLINE_CONNECTION_STATUS
          )?.length || 0;

        const offlineCamerasCount =
          totalDevices.filter(
            (device) =>
              !device.connectionStatus ||
              device.connectionStatus ===
                constants.DEVICES_OFFLINE_CONNECTION_STATUS
          )?.length || 0;

        return {
          orgId: org.orgId,
          totalDevicesCount: totalDevices.length + nvrDeviceList.length,
          totalOfflineDevicesCount:
            offlineCamerasCount + offlineNVRDevicesCount,
        };
      } catch (error) {
        return {
          orgId: org.orgId,
          totalDevicesCount: 0,
          totalOfflineDevicesCount: 0,
        };
      }
    });

    const values = await Promise.all(devicePromises);
    setDevicesCountByOrgIds(values.filter(Boolean));
  };

  const getExpiredLicenseCount = (licenses) => {
    let count = 0;
    try {
      licenses.forEach((license) => {
        if (
          license.licenseStatus === "EXPIRED" ||
          (license?.expiryDate &&
            Utils.getDateDifferenceInDays(
              license.expiryDate,
              moment().valueOf()
            ) <= 0)
        ) {
          count++;
        }
      });
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      return count;
    }
  };

  const isLicenseExpiredOrExpiringSoon = (license) => {
    let countOfExpiringSoonDays = false;
    const currentTimestamp = Date.now();
    const test =
      license?.expiryDate - license?.gracePeriod * 24 * 60 * 60 * 1000;

    const differenceInMilliseconds = Math.abs(test - currentTimestamp);
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    if (
      differenceInDays <= license?.expiringSoonInDays &&
      license?.licenseStatus !== "EXPIRED"
    ) {
      countOfExpiringSoonDays = true;
    }
    return countOfExpiringSoonDays;
  };

  const getExpiringSoonLicenseCount = (licenses) => {
    let count = 0;

    licenses.forEach((license) => {
      if (
        license.licenseStatus !== "UNASSIGNED" &&
        isLicenseExpiredOrExpiringSoon(license)
      ) {
        count++;
      }
    });

    return count;
  };

  const columnsData = useMemo(
    () => [
      {
        Header: constants.NAME_TABLE_HEADER,
        accessor: "orgName",
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values["orgName"].toLowerCase() >
            sortAfter.values["orgName"].toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values["orgName"].toLowerCase() <
            sortAfter.values["orgName"].toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      {
        Header: constants.CAMERAS_OFFLINE_TABLE_HEADER,
        accessor: "cameraOfflineCount",
        Cell: (props) => {
          if (props?.value) {
            const cameraOffline = props?.value;
            const [totalOfflineDevicesCount, totalDevicesCount] =
              cameraOffline?.split("/");
            if (props?.value !== null && props?.value !== undefined) {
              return (
                <>
                  {totalDevicesCount > 0 ? (
                    <>
                      {totalOfflineDevicesCount === 0 ? (
                        <>
                          {totalOfflineDevicesCount}
                          <HiOutlineCheckCircle
                            stroke={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--success_48")}
                            className="device-offline-count-icon ms-1"
                          />
                        </>
                      ) : (
                        <>
                          {cameraOffline}
                          <HiOutlineExclamationCircle
                            stroke={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--error_64")}
                            className="device-offline-count-icon ms-1"
                          />
                        </>
                      )}
                    </>
                  ) : (
                    0
                  )}
                </>
              );
            }
            return 0;
          } else {
            return 0;
          }
        },
        sortType: "basic",
      },
      {
        Header: constants.LICENSES_EXPIRED_TABLE_HEADER,
        accessor: "expiredLicenseCount",
        Cell: (props) => {
          return props.value || 0;
        },
        sortType: "basic",
      },
      {
        Header: constants.LICENSES_EXPIRING_TABLE_HEADER,
        accessor: "expiringSoonLicenseCount",
        Cell: (props) => {
          return props.value || 0;
        },
        sortType: "basic",
      },
      {
        Header: "",
        accessor: "orgId",
        maxWidth: 50,
        minWidth: 50,
        width: 50,
        Cell: (props) => {
          return "";
        },
      },
    ],
    []
  );

  const sortingData = useMemo(
    () => [
      {
        id: "orgName",
        desc: true,
      },
      {
        id: "devices",
        desc: true,
      },
    ],
    []
  );

  const hideModaInvite = () => {
    setPartnerInvitiationStatus(false);
    localStorage.removeItem("inviteData");
  };

  return (
    <div className="App partner-landing-page">
      {!showSpinner ? (
        <div>
          <div className="page-header">
            <Container className="mw-100">
              <Row>
                <div className="customer-list-toast-wrapper">
                  <SiteToast
                    customCss="customer-list-toast"
                    position="top-end"
                    show={showToast}
                    title="Error"
                    body={userMsg}
                    delay={5000}
                  />
                </div>
              </Row>
              <Row className="g-4">
                {loggedInUserData?.accountStatus !==
                  constants.INACTIVE_USER_STATUS_LABEL.toUpperCase() && (
                  <>
                    <Col
                      md={6}
                      lg={6}
                      xl={6}
                      xs={12}
                      className="page-title text-start"
                    >
                      {constants.MANAGE_ORG_PAGE_TITLE}
                    </Col>
                    <Col
                      md={6}
                      lg={6}
                      xl={6}
                      xs={12}
                      className="text-end page-header-buttons"
                    >
                      {getLoggedInUserPolicies()?.create_cust_org && (
                        <PrimaryButton
                          className="btn btn-primary"
                          type="button"
                          width="auto"
                          onClick={() => {
                            setShowAddOrgModal(true);
                          }}
                        >
                          <RxPlus
                            className="plus-icon-btn"
                            strokeWidth={"1.5px"}
                            size={13.33}
                          />
                          {constants.MANAGE_ORG_ADD_ORG_BUTTON_TITLE}
                        </PrimaryButton>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </div>

          <div className="list-block">
            <Container className="h-100 mw-100">
              <>
                {getLoggedInUserPolicies()?.view_cust_org === true &&
                  (Array.isArray(customerOrgDataList) &&
                  customerOrgDataList?.length > 0 ? (
                    <OrgListTable
                      columns={columnsData}
                      data={customerOrgDataList}
                      defaultSortByData={sortingData}
                    />
                  ) : Array.isArray(customerOrgDataList) &&
                    !customerOrgDataList?.length ? (
                    <NoDataBlock
                      contentBlock={
                        constants.NO_MANAGE_ORG_CUSTOMER_FOUND_TITLE
                      }
                    />
                  ) : null)}

                {userMsg && (
                  <div className="mt-5 mb-5">
                    <strong>{userMsg}</strong>
                  </div>
                )}
              </>
            </Container>
          </div>
        </div>
      ) : (
        <div className="position-absolute top-50 l-48">
          <SiteSpinner height="50px" width="50px" />
          <div className="mt-2 text-dark">{constants.LOADING}</div>
        </div>
      )}
      <SiteModal
        modalTitle={constants.NEW_CUSTOMER_ORG_PAGE_TITLE}
        showModal={showAddOrgModal}
        hideModal={() => setShowAddOrgModal(false)}
      >
        <NewOrganization
          reloadData={() => {
            fetchOrganizations(token);
          }}
          hideModal={() => setShowAddOrgModal(false)}
        />
      </SiteModal>
      <SiteModal
        modalTitle={inviteTitle}
        showModal={partnerInvitiationStatus}
        hideModal={() => hideModaInvite()}
        classes={"send-invitation-partner remove-org-modal"}
      >
        <div className={`sub-title-modal ${inviteCode === 1035 && "error-m"}`}>
          {inviteSubTitle}
        </div>
        {
          <div>
            <div className="org-remove-invite-status">
              <div className="device-claimed-status">
                <div
                  className={`${
                    inviteCode === 200 && inviteStatus
                      ? "claimed-view "
                      : (inviteCode === 200 && !inviteStatus) ||
                        inviteCode === 1032
                      ? "claimed-view reject"
                      : (inviteCode === 1030 || inviteCode === 1035) &&
                        "claimed-view warning"
                  }`}
                >
                  {inviteCode === 200 && (
                    <img
                      alt=""
                      className="image-tick"
                      src={inviteStatus ? icontick : cross}
                    ></img>
                  )}
                  {(inviteCode === 1030 || inviteCode === 1035) && (
                    <BsExclamationLg
                      size={40}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--warning_24")}
                    />
                  )}
                  {(inviteCode === 1031 || inviteCode === 1033) && (
                    <img className="image-tick" src={error} alt=""></img>
                  )}
                  {inviteCode === 1032 && (
                    <img className="image-tick" src={cross} alt=""></img>
                  )}
                </div>
              </div>
            </div>
            <PrimaryButton
              className="btn mt-4"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--brand_white")}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--brand_white")}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--brand_white")}
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--primary_40")}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--primary_72")}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--brand_white")}
              onClick={() => {
                hideModaInvite();
              }}
            >
              {constants.PARTNER_INVITE_BTN_LABEL}
            </PrimaryButton>
          </div>
        }
      </SiteModal>
    </div>
  );
}

export default PartnerLandingPage;
