import { PrimaryButton } from "../../../components/common";
import { constants } from "../../../helpers";
import { DeviceTypeEnum } from "../../../helpers/enums";

const RemoveDeviceModal = ({
  closeModal,
  fromDiscoveredDevices,
  onRemoveDevice,
  selectedDeviceToRemove,
}) => {
  return (
    <div className="location-area-selector-modal-wrapper">
      {fromDiscoveredDevices ? (
        <div className="modal-title">
          <div>{constants.DISCOVERED_DEVICES_MODAL_REMOVE_DEVICE_DESC1}</div>
          <div>{constants.DISCOVERED_DEVICES_MODAL_REMOVE_DEVICE_DESC2}</div>
        </div>
      ) : (
        <div className="modal-title">
          {selectedDeviceToRemove?.deviceType?.toUpperCase() ===
            DeviceTypeEnum.NVR ||
          selectedDeviceToRemove?.deviceType?.toUpperCase() ===
            DeviceTypeEnum.DMSERVER ||
          selectedDeviceToRemove?.deviceType?.toUpperCase() ===
            DeviceTypeEnum.WAVE
            ? constants.DEVICES_MODAL_REMOVE_GATEWAY_DEVICE_DESC
            : constants.DEVICES_MODAL_REMOVE_DEVICE_DESC}
        </div>
      )}
      <div className="button-container">
        <PrimaryButton
          className="device-btn-list btn-primary"
          type="button"
          width="100px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--primary_40")}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            "--brand_white"
          )}
          fontSize="14px"
          height="32px"
          fontWeight="400"
          borderRadius="4px"
          onClick={() => onRemoveDevice()}
        >
          {constants.DEVICES_MODAL_OK}
        </PrimaryButton>

        <PrimaryButton
          className="device-btn-list"
          type="button"
          width="100px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            "--greyscale_00"
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--greyscale_00")}
          fontSize="14px"
          height="32px"
          fontWeight="400"
          borderRadius="4px"
          onClick={() => closeModal()}
        >
          {constants.DEVICES_MODAL_CANCEL}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default RemoveDeviceModal;
