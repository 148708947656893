import React, { useState } from "react";
import { Calendar } from "react-multi-date-picker";
import moment from "moment";
import { PrimaryButton } from ".";
import { constants } from "../../helpers";

const DateTimePicker = ({ filterDate, onSubmit }) => {
  const todayNight = new Date();
  todayNight.setHours(23, 59, 59, 0);

  let [selectedDate, setSelectedDate] = useState([]);

  const minDateCalendar = new Date("1990-01-01");

  const onRangeApply = () => {
    let formatedSelectedDate = null;
    if (selectedDate?.length) {
      formatedSelectedDate = {
        startDate: selectedDate[0].toString(),
        endDate: selectedDate[1].toString(),
      };
    } else {
      formatedSelectedDate = {
        startDate: moment(new Date(getDate()?.[0]).getTime()).format(
          "MMM DD, YYYY"
        ),
        endDate: moment(new Date(getDate()?.[1]).getTime()).format(
          "MMM DD, YYYY"
        ),
      };
    }
    onSubmit(formatedSelectedDate);
  };

  const getDate = () => {
    const start = new Date(filterDate?.startDate);
    const end = new Date(filterDate?.endDate);
    const selectedDateObj = [
      new Date(
        `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
      ).getTime(),
      new Date(
        `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`
      ).getTime(),
    ];
    return selectedDateObj;
  };

  const onCickToday = () => {
    const lastNight = new Date();
    lastNight.setHours(0, 0, 0, 0);
    const todayNight = new Date();
    todayNight.setHours(23, 59, 59, 0);
    let formatedSelectedDate = null;
    formatedSelectedDate = {
      startDate: moment(lastNight.getTime()).format("MMM DD, YYYY"),
      endDate: moment(todayNight.getTime()).format("MMM DD, YYYY"),
    };
    onSubmit(formatedSelectedDate);
  };

  return (
    <div className="date-time-picker">
      <div>
        <Calendar
          range
          value={selectedDate?.length > 0 ? selectedDate : getDate()}
          onChange={setSelectedDate}
          minDate={new Date(moment(new Date(minDateCalendar)))}
          format="MMM DD, YYYY"
          monthYearSeparator=" "
          maxDate={new Date(moment(new Date(todayNight)))}
          shadow={false}
          highlightToday={true}
          weekStartDayIndex={1}
          headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
          className={`custom-calendar-range ${
            (selectedDate?.length &&
              selectedDate?.[0]?.toString() ===
                selectedDate?.[1]?.toString()) ||
            (selectedDate?.length &&
              selectedDate?.[0]?.toString() ===
                moment(new Date().getTime())
                  .format("MMM DD, YYYY")
                  .toString()) ||
            (!selectedDate?.length &&
              filterDate?.startDate === filterDate?.endDate)
              ? "custom-calendar-range-same"
              : ""
          } `}
        />
      </div>

      <div className="button-container">
        <div className="today" role="button" onClick={() => onCickToday()}>
          {constants.DEVICES_MODAL_TODAY}
        </div>
        <PrimaryButton
          className="btn btn-primary"
          type="submit"
          width="100px"
          height="32px"
          fontSize="14px"
          borderRadius="4px"
          onClick={onRangeApply}
          disabled={selectedDate?.length === 1}
        >
          {constants.DEVICES_MODAL_OK}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default DateTimePicker;
