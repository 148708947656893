import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { HiOutlineChevronDown } from "react-icons/hi";
import { useSelector } from "react-redux";
import { constants } from "../../../helpers";
import { getLocationsData } from "../../../store/reducers/AccountReducer";
import { PrimaryButton } from "../../../components/common";
import "./DiscoveredDevices.scss";
import { getAreasByLocationId } from "./DevicesAPI";

const LocationAreaSelectorModal = ({
  closeModal,
  updateDeviceLocationNArea,
  isD2CInSelectedDevices,
  mainSelectedLocation,
}) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");

  const locationDetails = useSelector(getLocationsData);

  const [selectedLocation, setSelectedLocation] =
    useState(mainSelectedLocation);

  const getDefaultSelectedArea = () => {
    const defaultLocation = locationDetails.find(
      (location) => location?.isDefault
    );
    return defaultLocation?.areas?.find((area) => area?.isDefault);
  };

  const [locationAreas, setLocationAreas] = useState(
    mainSelectedLocation.areas
  );
  const [selectedArea, setSelectedArea] = useState(getDefaultSelectedArea());

  const getLocationData = (locationId) => {
    if (!locationId) return;

    return locationDetails.find(
      (location) => location.locationId === locationId
    );
  };

  useEffect(() => {
    if (selectedLocation?.locationId) {
      fetchAreasbyLocationId(selectedLocation);
    }
  }, [selectedLocation?.locationId]);

  const getDefaultSelectedAreaFromList = (areas) => {
    return areas?.find((area) => area?.isDefault);
  };

  const fetchAreasbyLocationId = async (locationData) => {
    let areaAPIResponse = await getAreasByLocationId(orgId, locationData);
    if (areaAPIResponse?.length) {
      setLocationAreas(areaAPIResponse);
      setSelectedArea(getDefaultSelectedAreaFromList(areaAPIResponse));
    }
  };

  const onSelectLocationHandler = (evtKey) => {
    // evtKey should be a valid location ID
    if (!evtKey) return;

    const locationData = getLocationData(evtKey);
    setSelectedLocation(locationData);
    setLocationAreas(locationData?.areas);
    setSelectedArea(getDefaultSelectedAreaFromList(locationData?.areas));
  };

  const onSelectAreaHandler = (evtKey) => {
    if (!evtKey) return;
    let selectedArea = locationAreas.find((area) => area.areaId === evtKey);
    setSelectedArea(selectedArea);
  };

  const onClickOkay = () => {
    updateDeviceLocationNArea(selectedLocation, selectedArea);
    closeModal();
  };

  const locationDropdownContent =
    locationDetails?.length > 0 ? (
      locationDetails?.map((location) => (
        <Dropdown.Item
          key={`location-${location.locationId}`}
          className={`area-selector-menu-item ${
            selectedLocation?.locationId === location.locationId
              ? "selected"
              : ""
          }`}
          eventKey={location.locationId}
        >
          {location.locationName}
        </Dropdown.Item>
      ))
    ) : (
      <Dropdown.Item
        key={`location-${0}`}
        className={`area-selector-menu-item`}
        eventKey={undefined}
      >
        {constants.LOCATION_DROPDOWN_NO_LOCATION_DATA_TEXT}
      </Dropdown.Item>
    );

  const areaDropdownContent =
    locationAreas?.length > 0 ? (
      locationAreas?.map((area) => (
        <Dropdown.Item
          key={`area-${area.areaId}`}
          className={
            `area-selector-menu-item` +
            (selectedArea?.areaId === area.areaId ? " selected" : "")
          }
          eventKey={area.areaId}
        >
          {area.areaName}
        </Dropdown.Item>
      ))
    ) : (
      <Dropdown.Item
        key={`area-${0}`}
        className={`area-selector-menu-item`}
        eventKey={undefined}
      >
        {constants.LOCATION_DROPDOWN_NO_AREA_DATA_TEXT}
      </Dropdown.Item>
    );

  return (
    <div className="location-area-selector-modal-wrapper">
      <div className="modal-title">
        {constants.DEVICES_MODAL_SET_LOCATION_DESC}
      </div>
      <div className="dropdown-title">
        {constants.DEVICES_LIST_LOCATION_N_AREA}
      </div>
      <div className="dropdown-container">
        <div className="dropdown-row pb-3">
          {constants.DEVICES_MODAL_LOCATION}

          <Dropdown
            className="area-selector mr-3"
            onSelect={onSelectLocationHandler}
          >
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-location"
              className="area-selector-toggle"
              disabled={isD2CInSelectedDevices}
            >
              <div className="area-selected-wrapper">
                <span className="area-selected-name">
                  {selectedLocation?.locationName}
                </span>
              </div>
              <HiOutlineChevronDown size={20} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="area-selector-menu">
              {locationDropdownContent}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="dropdown-row pt-3 row-border">
          {constants.DEVICES_MODAL_AREA}
          <Dropdown className="area-selector" onSelect={onSelectAreaHandler}>
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-area"
              className="area-selector-toggle"
            >
              <div className="area-selected-wrapper">
                <span className="area-selected-name">
                  {selectedArea?.areaName}
                </span>
              </div>
              <HiOutlineChevronDown size={20} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="area-selector-menu">
              {areaDropdownContent}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="button-container">
        <PrimaryButton
          className="device-btn-list btn-primary"
          type="button"
          width="100px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--primary_40")}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            "--brand_white"
          )}
          fontSize="14px"
          height="32px"
          fontWeight="400"
          borderRadius="4px"
          onClick={() => onClickOkay()}
        >
          {constants.DEVICES_MODAL_OK}
        </PrimaryButton>

        <PrimaryButton
          className="device-btn-list"
          type="button"
          width="100px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--custom_color_31")}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            "--greyscale_00"
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--greyscale_00")}
          fontSize="14px"
          height="32px"
          fontWeight="400"
          borderRadius="4px"
          onClick={() => closeModal()}
        >
          {constants.DEVICES_MODAL_CANCEL}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default LocationAreaSelectorModal;
